// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';


// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.css'

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import * as React from 'react';
import App from './App';
import {Router} from "react-router";
import {createBrowserHistory} from 'history';
import {createRoot} from 'react-dom/client';
import {CollapseDrawerProvider} from "./contexts/CollapseDrawerContext";
import {SettingsProvider} from "./contexts/SettingsContext";
import {HelmetProvider} from "react-helmet-async";
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'components/miscrosoft/const';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const history = createBrowserHistory();
const container = document.getElementById('root');
Sentry.init({
  dsn: "https://51eb68839d3d86e850fe0baf979c3c8f@o4505481497804800.ingest.sentry.io/4505680003858432",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// @ts-ignore
const root = createRoot(container);
const msalInstance = new PublicClientApplication(msalConfig as Configuration);

root.render(
  <React.Fragment>
    <MsalProvider instance={msalInstance}>
      <Router history={history}>
        <HelmetProvider>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <App/>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </HelmetProvider>
      </Router>
    </MsalProvider>
  </React.Fragment>
);
