import { Box, Typography } from "@mui/material"
import '../components/mask.css'


interface RemplaceRegFunctionProps{
    text?:string
}
type MyType = {
     [key: string]: string | undefined 
  };

export const RemplaceRegFunction = (props:RemplaceRegFunctionProps) =>{

    const {text} = props

    const regExg =/\@\[(.*?)\]\((.*?)\)/g
    const regExgName = /\[(.*?)\]/g

    const textToRemplace = text?.match(regExg)
    const textName = text?.match(regExgName) ?? []
    let data:MyType = {} 
    let comment = text



    textToRemplace?.map((elt: keyof MyType,index) =>{
        data[elt] = '<span class="name_author_tag">' + '@'+(textName[index] ?? '[]').split('[').pop()?.split(']')[0] +'</span>'
    })

    comment = comment?.replace(regExg, m=>data[m] as string)




    return(
        <Box>
            <Typography component={'div'} dangerouslySetInnerHTML={{__html: comment as string}}  />  
        </Box>
    )
}