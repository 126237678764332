import { t } from "i18next";
import { useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import i18n from "translations";
import { useTranslation } from "react-i18next";

export const ChangeLanguageComponent = () => {
  const languages = [
    {
      code: "de",
      label: t("german"),
      img: "/static/icons/flags/ic_flag_de.svg",
    },
    {
      code: "en",
      label: t("english"),
      img: "/static/icons/flags/ic_flag_en.svg",
    },
  ];

  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(languages.find(
    (language) => language.code === i18n.language
  ) ?? languages[0]);
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );
  
  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  return (
    <>
      <Tooltip title = "">
        <IconButton onClick={handleOpenLangMenu} sx={{ p: 0, mr: "30px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "50%",
              mr: "6px",
            }}
          >
            <Box sx={{ mx: "15px", width: "25px", mt: "-3px" }}>
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={currentLanguage.img}
              />
            </Box>

            <Typography
              sx={{
                textOverflow: "ellipsis",
                color: "white",
                fontSize: "16px",
              }}
              textAlign="center"
            >
              {currentLanguage.label}
            </Typography>
          </Box>
          <img src="/static/icons/arrow_down.svg" alt="" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mb: "60px" }}
        id="menu-appbar"
        anchorEl={anchorElLang}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElLang)}
        onClose={() => setAnchorElLang(null)}
      >
        {languages.map((language, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              i18n.changeLanguage(language.code);
              setCurrentLanguage(language);
              setAnchorElLang(null);
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mx: "15px", width: "20px" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  src={language.img}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  maxWidth: "280px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                  }}
                  textAlign="center"
                >
                  {language.label}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
