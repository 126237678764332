import _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import BaseService from 'services/api/BaseService';
import {GlobalUrl} from 'services/api/urls';
import {Task} from 'store/types/ProjectType';
import {User} from 'store/types/UserTypes';
import {File, RollForward, TaskGroupData, TaskList, TaskPosition} from './../../../store/types/TaskType';

import JSZip, {folder} from 'jszip';
import {saveAs} from 'file-saver';


export const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const search_filter_function = (array: TaskList | any, keyword: string, setLoading: (loading: boolean) => void) => {
    setLoading(true)
    const data: TaskList[] = []
    array.find((elet: TaskList) => {
        if (elet.name?.toLowerCase().includes(keyword.toLowerCase())) {
            elet.tasks = []
            data.push(elet)
        }
        elet.children?.find((element: TaskList) => {
            if (element.name?.toLowerCase().includes(keyword.toLowerCase())) {
                if (elet.children?.includes(element)) {
                } else {
                    elet.children?.push(element)
                }
                if (data.includes(elet)) {
                } else {
                    data.push(elet)
                }
            } else {
                elet.children = []
            }
        })
        elet.tasks?.find((task: Task) => {
            if (task.name?.toLowerCase().includes(keyword.toLowerCase())) {
                if (data.includes(elet)) {
                    return
                } else {
                    data.push(elet)
                    elet.tasks?.push(task)

                }
                if (elet?.tasks?.includes(task)) {
                    return
                } else {
                    elet?.tasks?.push(task)
                }

            } else {
                elet.tasks = []
            }
        })
    })
    setTimeout(() => {
        setLoading(false)
    }, 500);

    return data
}
export const search_filter_user_function = (array: User[] | any, keyword: string, setLoading: (loading: boolean) => void) => {
    
    setLoading(true)
    const data: User[] = []
    array.find((elt: User) => {
        if (elt.name?.toLowerCase().includes(keyword) || elt.email?.toLowerCase().includes(keyword)) {
            if (data.includes(elt)) {
                return
            } else {
                data.push(elt)
            }
        }
    })

    setTimeout(() => {
        setLoading(false)
    }, 500);

    return data
}

export const getItemStyle = (isDragging: any, draggableStyle: any, grid: any) => ({
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#FFFFFF" : "#F8FAFF",
    border: isDragging ? "2px dashed grey" : "none",
    ...draggableStyle,
});

export const recurse = (arr: TaskList[], uuidToFind: string, objToMerge: Task, inAncestor = false, action: string) => {
    if (action == 'add') {
        return arr.map(obj => {
            const mergeThis = inAncestor || obj.uuid === uuidToFind;
            
            const merged = !mergeThis ? obj : {...obj, tasks: [objToMerge, ...(obj.tasks || [])]};
            if (merged.children) {
                
                merged.children = recurse(merged.children, uuidToFind, objToMerge, mergeThis, 'add');
            }
            return merged as TaskList;
        });
    } else if (action == 'delete') {
        return arr.map(obj => {
            const mergeThis = inAncestor || obj.uuid === uuidToFind;
            const merged = obj;
            if (merged.children) {
                merged.children = recurse(merged.children, uuidToFind, objToMerge, mergeThis, 'delete');
            }
            return merged as TaskList;
        });
    }
};

export const findParent = (arr: TaskList[], parent_uuid: string): TaskList => {
    return _.find(arr, function (taskgroup) {
        if (taskgroup.uuid == parent_uuid) {
            return taskgroup
        } else {
            return findParent((taskgroup.children ?? []) as TaskList[], parent_uuid)
        }
    }) as TaskList

    // if(uuid != ''){
    //     return uuid
    // }else{
    //   return  findParent(arr,'2')
    // }
}

export function isArrayOfNumbers(arr: any) {
    return Array.isArray(arr) && arr.every(Number.isFinite)
}

export const searchElement = (array: any, searchId: string, key: string) => {
    let foundElement = null

    array.forEach((obj:any) => {
        if (obj[key] === searchId) {
            foundElement = obj;
        } else if (obj.children) {
            const childResult = searchElement(obj.children, searchId, key);
            if (childResult) {
                foundElement = childResult;
            }
        }
    });

    return foundElement;
}

export const keywordFilter = (nodes: TaskList[], keyword: string | undefined) => {
    let newNodes: TaskList[] = [];
    for (let n of nodes) {
        if (n.children) {
            if (n.tasks) {
                n.tasks = keyword != undefined ? n.tasks.filter((item) => {
                    if (item.name?.toLowerCase().includes(keyword?.toLowerCase() ?? '') && keyword != undefined) {
                        newNodes.includes(n) ? null : newNodes.push(n)
                        return item
                    }
                }) : n.tasks
            }
            const nextNodes = keywordFilter(n.children, keyword);
            if (nextNodes.length > 0) {
                n.children = nextNodes;
            } else if (n?.name?.toLowerCase().includes(keyword?.toLowerCase() ?? '')) {
                n.children = nextNodes.length > 0 ? nextNodes : [];
            }
            if (
                nextNodes.length > 0 ||
                n?.name?.toLowerCase().includes(keyword?.toLowerCase() ?? '')
            ) {
                newNodes.includes(n) ? null : newNodes.push(n)
            }
        } else {
            if (n?.name?.toLowerCase().includes(keyword?.toLowerCase() ?? '')) {
                newNodes.includes(n) ? null : newNodes.push(n)
            }
        }
    }
    return newNodes;
};
export const uuidFilter = (nodes: TaskList[], uuid: string | undefined) => {
    let newNodes: TaskList[] = [];
    for (let n of nodes) {
        if (n.children) {
            if (n.tasks) {
                n.tasks = uuid != undefined ? n.tasks.filter((item) => {
                    if (item.uuid == uuid && uuid != undefined) {
                        newNodes.includes(n) ? null : newNodes.push(n)
                        return item
                    }
                }) : n.tasks
            }
            const nextNodes = uuidFilter(n.children, uuid);
            if (nextNodes.length > 0) {
                n.children = nextNodes;
            }
            if (
                nextNodes.length > 0 ||
                n?.uuid == uuid
            ) {
                newNodes.includes(n) ? null : (n.tasks || []).length > 0 ? newNodes.push(n) : newNodes.push(n.children[0])
                
            }
        } else {
            if (n?.uuid == uuid) {
                newNodes.includes(n) ? null : newNodes.push(n)
            }
        }
    }
    return newNodes;
};

export const useVesionning = () => {
    const {t} = useTranslation();
    return useQuery<UpdateData, Error>(
        ["versionning"],
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    GlobalUrl.VESRIONNING_UPDATE,
                    true
                );
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t("shared.internet_connexion_error"));
            }
            throw new Error(
                data?.message || data?.detail || t("shared.internet_connexion_error")
            );
        }
    );
};

export interface UpdateData {
    number?: string;
    date?: string;
    content?: string;
}

interface RemplaceRegFunctionProps {
    text: string
}

export const regExg = /\@\[(.*?)\]\((.*?)\)/g


export const scrollWindow = () => {
    if (window.scrollY != 0) {
        setTimeout(() => {
            window.scrollTo(0, window.scrollY - 50);
            scrollWindow();
        }, 10);
    }
}


export const DowloadZipFile = async (value: File[], setLoading: (loading: boolean) => void, project_name: string) => {
    setLoading(true)
    var zip = new JSZip()
    const remoteZipsFiles = value.map(async (folder, index) => {
        const response = await fetch(folder.url ?? '')
        const data = response.blob()
        zip.file(folder?.file_name ?? '', data)
        return data
    })
    Promise.all(remoteZipsFiles).then(() => {
        zip.generateAsync({type: 'blob'}).then((content) => {
            setLoading(false)
            saveAs(content, project_name + '.zip')
        })
    })
}

// export const getGroups = (arr: TaskList[], children: TaskList) => {
//     let groups: string[] = [];
//     for(let i = 0; i < arr.length; i++) {

//         if (children.parent_uuid != '' && children.parent_uuid == arr[i].uuid) {
//             return { groups: [ arr[i].uuid ?? ""] };
//         }
//         if ((arr[i].children ?? []).length > 0) {
//             let response = getGroups(arr[i].children ?? [], children);
//             if(Object.keys(response.groups).length > 0){
//                 groups = [ arr[i].uuid ?? "", ...response.groups];
//             }
//         }
//     }
//     return { groups };
// };
export const getGroups = (arr: TaskList[], children: TaskList, OrderTask?: string[], OrderChildren?: TaskPosition[]) => {
    
    let groups: TaskPosition[] = [];

    for (let i = 0; i < arr.length; i++) {
        if (children.parent_uuid != '' && children.parent_uuid == arr[i].uuid) {
            groups = [{
                task_group_uuid: arr[i].uuid ?? "",
                parent_uuid: arr[i].parent_uuid,
                children: [{
                    task_group_uuid: children.uuid,
                    parent_uuid: children.parent_uuid,
                    tasks: OrderTask ?? [],
                    children: OrderChildren ?? []
                }]
            }]
            return {group: groups};
        }
        if ((arr[i].children ?? []).length > 0) {
            let response = getGroups(arr[i].children ?? [], children, OrderTask);
            
            if ((response?.group ?? []).length > 0) {
                groups = [...groups, {
                    task_group_uuid: arr[i].uuid ?? '',
                    parent_uuid: arr[i].parent_uuid,
                    children: response.group
                }]
            }
        }
    }
    return {group: groups};
};

export const DefautPosition = (arr: TaskList[]) => {
    let groups: TaskPosition[] = []

    arr.map((elet, index) => {
        if (elet.parent_uuid == null) {
            groups.push({
                task_group_uuid: elet.uuid,
                parent_uuid: elet.parent_uuid,
                tasks: arrayOfString(elet.tasks),
                children: []
            })
        }
        if ((elet.children ?? []).length != 0) {
            groups.map((position) => {
                if (position.task_group_uuid == (elet.children ?? [])[0].parent_uuid) {
                    position.children = getChildrenPosition((elet.children ?? []), position.children ?? [])
                }
            })
        }
    })
    return groups
}

export const getChildrenPosition = (arr: TaskList[], groups: TaskPosition[]) => {
    arr.map((elet, index) => {
        groups.push({
            task_group_uuid: elet.uuid,
            parent_uuid: elet.parent_uuid,
            tasks: arrayOfString(elet.tasks),
            children: []
        })
        if ((elet.children ?? []).length != 0) {
            groups.map((position) => {
                if (position.task_group_uuid == (elet.children ?? [])[0].parent_uuid) {
                    position.children = getChildrenPosition((elet.children ?? []), position.children ?? [])
                }
            })
        }
    })
    return groups
}
export const arrayOfString = (arr: any) => {
    let arrString: string[] = []

    arr.forEach((element: any) => {
        arrString.push(element.uuid)
    });
    return arrString
}

export const deleteElementInPositionsObject = (positions: TaskPosition[], elementToDelete: string[]) => {


}

const sortTasksRecursively = (task_lists: TaskList[], task_group_uuid_positions: TaskPosition[]): TaskList[] => {
     
    // Sort the current array of tasks by position
    task_lists.sort((a: TaskList, b: TaskList) => {
        const parentIndex1 = task_group_uuid_positions.findIndex((elt) => elt.task_group_uuid === a.parent_uuid ?? "")
        const parentIndex2 = task_group_uuid_positions.findIndex((elt) => elt.task_group_uuid === b.parent_uuid ?? "")
        const index1 = (a.parent_uuid ?
            (
                task_group_uuid_positions[parentIndex1]?.children?.findIndex((elt) => elt.task_group_uuid === a.uuid ?? "")
            )
            :
            task_group_uuid_positions.findIndex((elt) => elt.task_group_uuid === a.uuid ?? "")
        ) ?? a.position ?? 0;
        const index2 = (b.parent_uuid ?
            (
                task_group_uuid_positions[parentIndex2]?.children?.findIndex((elt) => elt.task_group_uuid === b.uuid ?? "")
            )
            :
            task_group_uuid_positions.findIndex((elt) => elt.task_group_uuid === b.uuid ?? "")
        ) ?? b.position ?? 0;
        return index1 - index2;
    });
  
    // Recursively sort children and tasks for each task
    task_lists.forEach((task_list) => {
      if (task_list.children && task_list.children.length > 0) {
        task_list.children = sortTasksRecursively(task_list.children, task_group_uuid_positions);
      }
      
      const sub_task_group_uuid_position = task_group_uuid_positions.find((task_group_uuid_position) => task_group_uuid_position.task_group_uuid === task_list.uuid)

      if (task_list.tasks && task_list.tasks.length > 0) {
        if (sub_task_group_uuid_position) {
            task_list.tasks = task_list.tasks?.sort((a: Task, b: Task) => (
                (sub_task_group_uuid_position.tasks?.indexOf(a.uuid ?? "") ?? a.position ?? 0) - 
                (sub_task_group_uuid_position.tasks?.indexOf(b.uuid ?? "") ?? b.position ?? 0)
            )) ?? [];
        } else {
            task_list.tasks = task_list.tasks?.sort((a: Task, b: Task) => (
                (a.position ?? 0) - 
                (b.position ?? 0)
            )) ?? [];
        }
      }
    });
  
    return task_lists;
}

export const reoder_task_group_and_tasks = (data: TaskGroupData,) => {

    data.data = sortTasksRecursively(_.cloneDeep(data?.data ?? []), data.position?.task_group_uuid_positions ?? [])
    
    return data;
    let dataToSend: TaskList[] = []
    let dataToSend2: TaskList[] = []
    let dataTaskGroup = data?.position?.task_group_uuid_positions ?? []
    dataToSend2 = _.cloneDeep(data?.data ?? []).sort((a, b) => {
        return (data?.position?.task_group_uuid_positions ?? []).findIndex(x => x.task_group_uuid == a.uuid) - (data?.position?.task_group_uuid_positions ?? []).findIndex(x => x.task_group_uuid == b.uuid)
    })
    dataToSend = _.cloneDeep(dataToSend2).map((item, index) => {
        
        if ((item.tasks ?? []).length > 0 && item?.tasks) {
        
            let indexOfParent = dataTaskGroup.findIndex(x => x.task_group_uuid == item.uuid)

            if (indexOfParent != -1) {
                item.tasks = _.cloneDeep(item.tasks).sort((a, b) => {
                    return (((data?.position?.task_group_uuid_positions ?? [])[indexOfParent].tasks ?? [])).findIndex(x => x == a.uuid) - (((data?.position?.task_group_uuid_positions ?? [])[indexOfParent].tasks ?? [])).findIndex(x => x == b.uuid)
                })
            }
        }
        if ((item.children ?? []).length > 0) {
            let indexOfParentChildreen = dataTaskGroup.findIndex(x => x.task_group_uuid == item.uuid)
            if (indexOfParentChildreen != -1) {           
                item.children = sort_array_on_another(_.cloneDeep(item.children ?? []), (((data?.position?.task_group_uuid_positions ?? [])[indexOfParentChildreen].children ?? [])))
            }
        }
        return item
    })


    return {...data, data: dataToSend}
}

export const sort_array_on_another = (arr: TaskList[], orderArray: TaskPosition[]) => {
    let dataToSend: TaskList[] = []
    dataToSend = _.cloneDeep(arr).sort((a, b) => {
        return (orderArray).findIndex(x => x.task_group_uuid == a.uuid) - (orderArray).findIndex(x => x.task_group_uuid == b.uuid)
    })
    arr = _.cloneDeep(dataToSend).map((item, index) => {
        if ((item.tasks ?? []).length > 0 && item?.tasks) {
            let indexOfParent = (orderArray).findIndex(x => x.task_group_uuid == item.uuid)

            if (indexOfParent != -1) {
                item.tasks = _.cloneDeep(item.tasks).sort((a, b) => {
                    return (((orderArray)[indexOfParent].tasks ?? [])).findIndex(x => x == a.uuid) - (((orderArray)[indexOfParent].tasks ?? [])).findIndex(x => x == b.uuid)
                })
            }

        }
        if ((item.children ?? []).length > 0) {
            let indexOfParent = (orderArray).findIndex(x => x.task_group_uuid == item.uuid)
            if (indexOfParent != -1) {
                item.children = sort_array_on_another(_.cloneDeep(item.children ?? []), (((orderArray)[indexOfParent].children ?? [])))
                
            }
        }
        return item
    })
    return arr
}
