import {Group, Pagination} from "store/types";
import BaseService from './BaseService';
import {GroupeUrls, SiteUrls} from './urls';

class GroupesService {
  static createGroup = (groupe: Group) => {
      return BaseService.postRequest(GroupeUrls.CREATE_GROUPE,groupe,true)
  };
  static listSites = (p: Pagination) => {
      return BaseService.getRequest(SiteUrls.READ_SITE(p),true)
  };

}

export default GroupesService;
