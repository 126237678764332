import { ThunkAction } from 'redux-thunk';
import { ApplicationAction, ApplicationState, Question, User } from 'store/types';
import { loginSuccess, updateUserAvatarFaillure, updateUserAvatarRequest, updateUserAvatarSuccess } from 'store/actions';
import { AuthService, UserService } from 'services/api';
import { impersonateLoginRequest, impersonateLoginSuccess, loginError, loginRequest } from 'store/actions/AccountActions';
import SafeService from "../../services/api/SafeService";
import SecureLS from "secure-ls";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

const ls = new SecureLS({ encodingType: 'aes' });

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;

export const getUserInfo = (): Effect => async (dispatch, getState) => {
    const account = getState().account
    return await AuthService.get_userInfo()
        .then(async (res: any) => {
            if (res.status === 200) {
                let user = await res.json();
                return dispatch(loginSuccess({ ...account.user, ...user }, account.token || ''));
            }
        })
        .catch((err: any) => {
            console.log(err);
        });
};

export const updateUserInfoEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
        const { account, i18n: { t = (key: string) => key } } = getState();
        setLoading(true);
        return AuthService.update_userInfo(payload)
            .then(async (res: any) => {
                const status = res.status === 200 || res.status === 201;
                let { user, message } = await res.json();
                enqueueSnackbar(message, {
                    variant: status ? 'success' : 'warning'
                });
                if (status) {
                    return dispatch(loginSuccess({ ...account.user, ...user }, account.token || ''));
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(t('shared.internet_connexion_error'), {
                    variant: 'error'
                });
            })
            .finally(() => setLoading(false))
    };


export const updatePasswordEffect = (
    payload: any,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    enqueueSnackbar: Function
)
    : Effect => async (dispatch, getState) => {
        const { i18n: { t = (key: string) => key } } = getState();
        setLoading(true);
        return AuthService.update_password(payload)
            .then(async (res: any) => {
                const status = res.status === 200 || res.status === 201;
                let { message } = await res.json();
                if (status) onSuccess()
                enqueueSnackbar(message, {
                    variant: status ? 'success' : 'warning'
                });
            })
            .catch((err: any) => {
                enqueueSnackbar(t('shared.internet_connexion_error'), {
                    variant: 'error'
                });
            })
            .finally(() => setLoading(false))
    };

export const updateUserAvatarEffect = (
    payload: { file_name: string, base_64: string },
    onError: (msg: string) => void,
    onSuccess: (user: User) => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(updateUserAvatarRequest());

    return UserService.update_userAvatar(payload).then(async (res: any) => {
        if (res.status === 200 || res.status === 201) {
            let user = await res.json();
            dispatch(updateUserAvatarSuccess(user));
            onSuccess(user);
        } else {
            let { message, detail } = await res.json();
            onError(message || detail);
            dispatch(updateUserAvatarFaillure(message || detail));
        }
    })
}

export interface BrowserConnected {
    id_connected: string,
    name: string,
    system: string

}

export const loginEffect = (
    email: string,
    password: string,
    admin: boolean,
    site_uuid: string,
    browser: BrowserConnected,
    onError: (msg: string) => void,
    onSuccess: (user: User) => void,
    handleConfirm: () => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(loginRequest());
    const payload = {
        login: email,
        password: password,
        admin: admin,
        site_uuid: site_uuid,
        ...(browser ? {browser: browser} :  {})
    }

    return AuthService.login(payload)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let {
                    user,
                    token
                } = await res.json();
                dispatch(loginSuccess(user, token.access_token));

                onSuccess(user);
            } else if (res.status === 202) {
                handleConfirm();
                dispatch(loginError('confirmation'));

            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
                dispatch(loginError(message || detail));
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
            return dispatch(loginError(t('shared.internet_connexion_error')));
        })
};


export const impersonateLoginEffect = (
    uuid: string,
    onError: (msg: string) => void,
    onSuccess: (user: User) => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(impersonateLoginRequest());
   

    return AuthService.impersonateLogin(uuid)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let {
                    user,
                    token
                } = await res.json();
                dispatch(impersonateLoginSuccess(user, token.access_token));

                onSuccess(user);
            }else {
                let { message, detail } = await res.json();
                onError(message || detail);
                dispatch(loginError(message || detail));
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
            return dispatch(loginError(t('shared.internet_connexion_error')));
        })
};

export const loginAzureEffect = (
    microsoft_token: string,
    onError: (msg: string) => void,
    onSuccess: (user: User) => void,
    handleConfirm: () => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(loginRequest());
    const payload = {
        microsoft_token: microsoft_token,
    }

    return AuthService.loginAzure(payload)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let {
                    user,
                    token
                } = await res.json();
                dispatch(loginSuccess(user, token.access_token));

                onSuccess(user);
            } else if (res.status === 202) {
                handleConfirm();
                dispatch(loginError('confirmation'));

            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
                dispatch(loginError(message || detail));
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
            return dispatch(loginError(t('shared.internet_connexion_error')));
        })
}


export type AuthMode = 'login' | 'sign'

export const safeZoneAuthEffect = (
    data: any,
    setLoading: (loading: boolean) => void,
    onError: (msg: string) => void,
    onSuccess: () => void,
    mode: AuthMode,
    question?: Question,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key },
        account: { user, token },
    } = getState();
    setLoading(true);
    return (mode === 'login' ? SafeService.login : SafeService.sign_in)(data)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let { safe_token } = await res.json();

                dispatch(loginSuccess({
                    ...user,
                    safe_token,
                    question
                }, token || ''));
                onSuccess();
            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
        })
        .finally(() => setLoading(false))
};

export const updateCodeOrQuestionEffet = (
    data: any,
    setLoading: (loading: boolean) => void,
    onError: (msg: string) => void,
    onSuccess: () => void,
    mode: AuthMode,
    isToValidate: boolean,
    question?: Question,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key },
        account: { user, token },
    } = getState();
    setLoading(true);

    return SafeService.resetCodeOrQuestion(data, isToValidate).then(async (res: any) => {
        if (res.status === 200 || res.status == 201) {
            let data = await res.json()
            onSuccess()
        }
    }).catch((err: any) => {
        onError(t('shared.internet_connexion_error'))
    }).finally(() => setLoading(false))
}


export const loginConfirmEffect = (
    code: string,
    browser: BrowserConnected,
    onError: (msg: string) => void,
    onSuccess: (user: any) => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    dispatch(loginRequest());
    return AuthService.confirmLogin(code, browser.id_connected, browser.name, browser.system)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                let {
                    user,
                    token
                } = await res.json();

                let oldData = []
                oldData = ls.get('browser_info') || []
                oldData.push({ email: user.email, browser })
                ls.set("browser_info", oldData)
                dispatch(loginSuccess(user, token.access_token));
                onSuccess(user);
            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
                dispatch(loginError(message || detail));
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
            return dispatch(loginError(t('shared.internet_connexion_error')));
        })
};


export const resendEmailLogin = (
    email: string,
    onError: (msg: string) => void,
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    setLoading(true);
    return AuthService.resendLoginMail(email)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                onSuccess();
            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
        })
        .finally(() => setLoading(false))
};


export const startResetPasswordEffect = (
    email: string,
    setLoading: Function,
    onError: (msg: string) => void,
    onSuccess: (msg: string) => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    setLoading(true);
    return AuthService.sendEmailForResetPassword(email)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                const { message } = await res.json();
                onSuccess(message);
            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
        })
        .finally(() => setLoading(false))
};


export const sendNewPasswordEffect = (
    payload: { email: string, code: string, new_password: string },
    setLoading: Function,
    onError: (msg: string) => void,
    onSuccess: (msg: string) => void,
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key }
    } = getState();
    setLoading(true);
    return AuthService.sendNewPassword(payload)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                const { message } = await res.json();
                onSuccess(message);
            } else {
                let { message, detail } = await res.json();
                onError(message || detail);
            }
        })
        .catch((err: any) => {
            onError(t('shared.internet_connexion_error'))
        })
        .finally(() => setLoading(false))
};

interface ChangePasswordProps {
    password: string
}

export const changePasswordEffect = (
    payload: {
        password: string,
        current_password?: string
    },
    setLoading: Function,
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
    onSuccess?: Function
): Effect => async (dispatch, getState) => {
    const {
        i18n: { t = (key: string) => key },
        account: { token }
    } = getState();
    dispatch(loginRequest());
    setLoading(true);

    return AuthService.changePassword(payload)
        .then(async (res: any) => {
            if (res.status === 200 || res.status === 201) {
                const user = await res.json();
                dispatch(loginSuccess(user, token ?? ""));
                enqueueSnackbar(t<string>('label_password_updated'), {
                    variant: "success"
                });
                if (onSuccess)
                    onSuccess()
            } else {
                const { detail } = await res.json();
                enqueueSnackbar((detail), {
                    variant: "warning"
                })
            }
        })
        .catch((err: any) => {
            enqueueSnackbar(t<string>('shared.internet_connexion_error'), {
                variant: "error"
            })
        })
        .finally(() => setLoading(false))
};

