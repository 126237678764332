// @flow
import * as React from 'react';
import { Box, Typography } from "@mui/material";
import { Document } from "../../../../store/types/NotificationType";


type Props = {
    notifications?: Document[];
    handleClick: (notification: Document) => void
};


const Notification = (props: Props) => {

    const mock = props.notifications || []

    return (
        <Box sx={{
            position: 'relative',
            padding: '13px 10px 8px 10px',
            maxHeight: 245,
            overflow: 'auto',
            bgcolor: 'common.white',
            borderTop: (theme) => `4px solid ${theme.palette.error.main}`
        }}>

            {
                mock.map((notif, k) => <Box
                    onClick={() => props.handleClick(notif)}
                    key={notif.id}
                    sx={{
                        borderBottom: k === mock.length - 1 ? 'none' : '1px solid #B8B8B8',
                        paddingBottom: '5px',
                        cursor: 'pointer',
                        mb: k === mock.length - 1 ? 0 : '10px'
                    }}>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant={'caption'}
                            sx={{
                                flexGrow: 1
                            }}
                            lineHeight={'18px'} fontWeight={700} component={'p'}>
                            {notif.payload_json?.title}
                        </Typography>
                        {!notif.read && <Box sx={{
                            minWidth: '14px',
                            height: '14px',
                            padding: '0px 3px',
                            bgcolor: 'primary.main',
                            borderRadius: '100%',
                            display: 'flex',
                            fontSize: 9,
                            ml: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#ffffff'
                        }}>
                            1
                        </Box>}
                    </Box>
                    {/*<Typography variant={'caption'} lineHeight={'18px'} component={'p'}>*/}
                    {/*    {notif.enterprise}*/}
                    {/*</Typography>*/}
                    <Typography variant={'caption'}
                        sx={{ color: 'text.grey' }}
                        fontSize={'9px'} lineHeight={'18px'} component={'p'}>
                        {notif.payload_json?.content}
                    </Typography>
                </Box>)
            }
        </Box>
    );
};

export default Notification