import {
    AppBar,
    Avatar,
    Badge,
    Box,
    Button,
    Collapse,
    Container,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import {makeStyles, useTheme} from "@mui/styles";
import React, {useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {ButtonToDecorate} from "./component/customHeaderButton";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "store/actions";
import SecureLS from 'secure-ls';

import {useSnackbar} from "notistack";
import {ApplicationState} from "store/types";
import {useLocation} from "react-router-dom";
import {Document} from "../../../store/types/NotificationType";
import Notification from "../../../views/home/components/Notifications/Notification";
import {useNotificationsHome} from "views/clients/clientHooks";
import NotificationDetail from "views/home/components/NotificationDetail";
import zIndex from "@mui/material/styles/zIndex";
import { ChangeLanguageComponent } from 'layouts/Application/header/component/change_language';

interface DashboardHeaderProps {
    onMobileNavOpen: any;
    className?: string;
}

interface CustomButtonI {
    title: string;
    img: string;
    href: string;
}

type NotificationType = "notifications" | "documents" | "messages";

export const filter_by_read = (notif: Document) => !notif.read;

const useStyles = makeStyles((theme: Theme) => ({
    projectButton: {
        background: "#008EAA",
        minHeight: "34px",
        paddingTop: "6px",
        marginRight: "15px",
        paddingBottom: "6px",
        paddingLeft: "17px",
        paddingRight: "17px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
}));

const ls = new SecureLS({encodingType: 'aes'});

const DashboardHeader = (props: DashboardHeaderProps) => {
    const {onMobileNavOpen, className, ...rest} = props;
    const {t, i18n} = useTranslation();

    const location = useLocation();
    const history = useHistory();
    const {user} = useSelector((state: ApplicationState) => state.account);
    let user_2 = ls.get('user_2')
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme: Theme = useTheme();
    const isAdmin = user?.role?.code === "administrator";
    const isOfficeBoss = user?.role?.code == "office_boss";
    const canView = ['office_boss', 'consultant']
    const isMandant = user?.role?.code === "mandant";

    const [keyword, setKeyword] = useState("");
    const [notificationToShow, setNotificationToShow] = useState<
        Document | undefined
    >();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));

    const shapeStyles = {
        bgcolor: "#008EAA",
        width: 34,
        height: 34,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // marginRight: "17px",
    };
    const shapeCircleStyles = {borderRadius: "50%"};
    const settings = [
        {label: "Profile", img: "/static/icons/user_profile.svg"},
        ...(!isMandant
            ? []
            : [{label: "SafeZone", img: "/static/icons/safe_zone_icon.svg"}]),
        ...(user_2 != '' ? [] : [{label: "Logout", img: "/static/icons/logout_icon.svg"}])
    ];

    // const languages = [
    //     {code: "de", label: t("german"), img: "/static/icons/flags/ic_flag_de.svg"},
    //     {code: "en", label: t("english"), img: "/static/icons/flags/ic_flag_en.svg"}
    // ];

    // const [currentLanguage, setCurrentLanguage] = useState(languages[0]);

    const manageSetting: CustomButtonI[] = [
        ...(isAdmin
            ? ([
                {
                    title: t("group_name"),
                    img: "/static/icons/group_icon_2.svg",
                    href: "/app/group",
                },
            ] as CustomButtonI[])
            : []),
        ...(isAdmin || canView.includes(user?.role?.code ?? '')
            ? ([
                {
                    title: t("title_management_user_title"),
                    img: "/static/icons/user_action_2.svg",
                    href: "/app/users",
                },
            ] as CustomButtonI[])
            : []),
        {
            title: t("client_title_page"),
            img: "/static/icons/client_icon.svg",
            href: "/app/client",
        },
        ...(isAdmin || canView.includes(user?.role?.code ?? '')
            ? ([
                {
                    title: t("log_page_title"),
                    img: "/static/icons/newspaper.svg",
                    href: "/app/logs",
                },
            ] as CustomButtonI[])
            : []),

        // ...(canView.includes(user?.role?.code ?? '')
        //     ? ([
        //         {
        //             title: t("document_menu_title"),
        //             img: "/static/icons/document_icon.svg",
        //             href: "/app/documents",
        //         },
        //     ] as CustomButtonI[])
        //     : []),
        ...(canView.includes(user?.role?.code ?? '')
            ? ([
                {
                    title: t("authorization_title"),
                    img: "/static/icons/user_management.svg",
                    href: "/app/authorization",
                },
            ] as CustomButtonI[])
            : []),
        ...(canView.includes(user?.role?.code ?? '') || isAdmin
            ? ([
                {
                    title: t("label_trashcan"),
                    img: "/static/icons/draft_icon.svg",
                    href: "/app/trashcan",
                },
            ] as CustomButtonI[])
            : []),
        {
            title: t('translate_key_invitation_title_setting'),
            img: "/static/icons/invitation.png",
            href: "/app/invitation"
        }
    ];

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );
    // const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    //     null
    // );
    const {enqueueSnackbar} = useSnackbar();
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    // const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorElLang(event.currentTarget);
    // };

    const refNotification = useRef<HTMLDivElement>(null);
    const {
        isLoading: isLoadingNotifications,
        isFetching,
        data: notifications,
    } = useNotificationsHome();

    const [currentNotification, setCurrentNotification] = useState<
        NotificationType | undefined
    >();

    const handleMenuClose = () => setCurrentNotification(undefined);

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleLogout = async () => {
        try {
            handleCloseNavMenu();
            await dispatch(logout());
            history.push("/");
        } catch (error) {
            enqueueSnackbar(t("unable_to_disconnect"), {
                variant: "error",
            });
        }
    };

    const unreadNotification: number = useMemo(
        () => (notifications?.notifications || []).filter((e) => !e.read).length,
        [notifications?.notifications]
    );

    const handleCloseUserMenu = (value: any) => {
        if (value.label == "Logout") {
            handleLogout();
        }
        let href = "";
        if (value.label == "SafeZone") {
            href = "/app/safe-zone";
        } else if (value.label === "Dashboard") {
            href = "/app/home";
        } else if (value.label === "Activity Log") {
            href = "/app/log";
        } else if (value.label === "Profile") {
            href = "/app/profile";
        }
        if (location.pathname === href) window.location.reload();
        else history.push(href);
        setAnchorElUser(null);
    };
    const [showMenue, setShowMenue] = useState<boolean>(false);
    const handleToggleNotification = (tab?: NotificationType) => {
        if (tab == currentNotification) {
            setCurrentNotification(undefined);
        } else if (typeof currentNotification === "undefined") {
            setCurrentNotification(tab);
        } else {
            setCurrentNotification(undefined);
            setTimeout(() => {
                setCurrentNotification(tab);
            }, 500);
        }
    };

    return (
        <>
            {notificationToShow && (
                <NotificationDetail
                    onClose={() => setNotificationToShow(undefined)}
                    notification={notificationToShow}
                />
            )}
            {showMenue && <SidebarContainer onClose={() => setShowMenue(false)}/>}
            <AppBar color={"primary"} className={className} elevation={0} {...rest}>
                <Container
                    maxWidth={"xl"}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: {xs: "5px"},
                    }}
                >

                    
                    <Hidden smDown>
                        {!isAdmin && (
                            <ButtonToDecorate
                                className={classes.projectButton}
                                onPress={() => {
                                    if (location.pathname === "/app/home") {
                                        //reload current window
                                        window.location.reload();
                                    } else history.push("/app/home");
                                }}
                                onMobileNavOpen={undefined}
                                title={t("project_title_button_1")}
                                icon="/static/icons/project_icon.svg"
                            />
                        )}
                    </Hidden>
                    <Hidden smDown>
                        {["mandant"].includes(user?.role?.code ?? "") ? (
                            ""
                        ) : (
                            <ButtonToDecorate
                                onPress={handleOpenNavMenu}
                                className={classes.projectButton}
                                onMobileNavOpen={undefined}
                                title={t("project_title_button_2")}
                                endIcon={"/arrow_down.svg"}
                                icon="/static/icons/gestion.svg"
                            />
                        )}
                        <Menu
                            sx={{mt: "50px"}}
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                        >
                            <Paper sx={{width: "340px !important", maxWidth: "100%"}}>
                                {manageSetting.map((manageSetting) => (
                                    <MenuItem
                                        key={manageSetting.title}
                                        onClick={() => {
                                            handleCloseNavMenu();
                                            if (location.pathname === manageSetting.href)
                                                window.location.reload();
                                            else history.push(manageSetting.href);
                                        }}
                                    >
                                        <Box sx={{ mx: "14px",minHeight:'20px',minWidth:'22px','& img':{
                                                width:'100%',height: '100%',...(manageSetting.href ==='/app/invitation' ? {height:'29px'} : {})
                                            } }}>
                                        <img src={manageSetting.img} alt=""/>
                                        </Box>
                                        <Typography sx={{ml: "14px"}} textAlign="center">
                                            {manageSetting.title}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Paper>
                        </Menu>
                    </Hidden>
                    <Hidden smDown>
                        <ButtonToDecorate
                            className={classes.projectButton}
                            onPress={() => {
                                if (location.pathname === "/app/news") {
                                    //reload current window
                                    window.location.reload();
                                } else history.push("/app/news");
                            }}
                            onMobileNavOpen={undefined}
                            title={t("project_title_button_3")}
                            icon="/static/icons/news_icon.svg"
                        />
                    </Hidden>
                    <Box sx={{flexGrow: 1, textAlign: 'center'}}>
                        <Typography sx={{fontWeight: 'bold'}}>
                            {user?.site?.name ?? '--/--/--'}
                        </Typography>
                    </Box>
                    <Hidden smUp>
                        <Box
                            sx={{
                                display: "flex",
                                width: "50%",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                onClick={() => setShowMenue(true)}
                                sx={{height: "12px", minWidth: "18px"}}
                            >
                                <img src="/menu_icon.svg" alt="menu"/>
                            </Button>
                            <img src="/logo_white.svg" alt="logo mandatal"/>
                        </Box>
                    </Hidden>

                    <Box flexGrow={1}/>
                    <ChangeLanguageComponent></ChangeLanguageComponent>
                    {location.pathname == "/app/home" ? (
                        ""
                    ) : (
                        <Tooltip title={t("top_bar_notif_tooltip")}>
                            <Badge
                                badgeContent={unreadNotification}
                                color="error"
                                sx={{marginRight: "17px"}}
                            >
                                <Box
                                    onClick={() => handleToggleNotification("notifications")}
                                    component="span"
                                    position={"relative"}
                                    sx={{...shapeStyles, ...shapeCircleStyles}}
                                >
                                    <img
                                        height={20}
                                        width={16}
                                        src="/static/icons/notification_icon.svg"
                                        alt=""
                                    />
                                </Box>
                            </Badge>
                        </Tooltip>
                        
                    )}



                    <Menu
                        anchorEl={refNotification.current}
                        onClose={handleMenuClose}
                        sx={{
                            transform: "translate(-40px, 8px)",
                            "& ul": {
                                pt: 0,
                            },
                            "& .MuiPaper-root": {
                                top: "45px !important",
                                left: "82% !important",
                                width: "320px",
                                marginLeft: matches ? "40px" : "0px",
                                maxWidth: "calc(100% - 24px)",
                            },
                        }}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: matches ? "right" : "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: matches ? "right" : "left",
                        }}
                        open={currentNotification === "notifications"}
                    >
                        <Notification
                            handleClick={setNotificationToShow}
                            notifications={notifications?.notifications}
                        />
                    </Menu>

                    <Tooltip title={t("top_bar_profile_tooltip")}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Box
                                sx={{
                                    p: "1px",
                                    border: "solid 1px #008EAA",
                                    borderRadius: "50%",
                                    mr: "6px",
                                }}
                            >
                                <Avatar
                                    sx={{height: 29, width: 29}}
                                    alt="profile"
                                    src={user?.avatar?.url}
                                />
                            </Box>
                            <img src="/static/icons/arrow_down.svg" alt=""/>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{mt: "50px"}}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => handleCloseUserMenu(setting)}
                            >
                                <Box sx={{display: "flex"}}>
                                    <Box sx={{mx: "15px", height: '14px', width: '14px'}}>
                                        <img style={{
                                            width: '100%',
                                            height: '100%'
                                        }} src={setting.img}/>
                                    </Box>
                                    <Box sx={{
                                        flexGrow: 1,
                                        maxWidth: '280px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        <Typography sx={{
                                            textOverflow: 'ellipsis'
                                        }} textAlign="center">{setting.label}</Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Menu>
                </Container>
            </AppBar>
        </>
    );
};

export default DashboardHeader;

type SidebarProps = {
    onClose: () => void;
};

const SidebarContainer = (props: SidebarProps) => {
    const {t, i18n} = useTranslation();
    const {user} = useSelector((state: ApplicationState) => state.account);
    const isAdmin = user?.role?.code === "administrator";
    const isOfficeBoss = user?.role?.code == "office_boss";

    const location = useLocation();
    const history = useHistory();
    const {onClose} = props;
    const [open, setOpen] = React.useState(true);
    const manageSetting: CustomButtonI[] = [
        ...(isAdmin
            ? ([
                {
                    title: t("group_name"),
                    img: "/static/icons/group_icon_2.svg",
                    href: "/app/group",
                },
            ] as CustomButtonI[])
            : []),
        ...(isAdmin || isOfficeBoss
            ? ([
                {
                    title: t("title_management_user_title"),
                    img: "/static/icons/user_action_2.svg",
                    href: "/app/users",
                },
            ] as CustomButtonI[])
            : []),
        {
            title: t("client_title_page"),
            img: "/static/icons/client_icon.svg",
            href: "/app/client",
        },
        ...(isAdmin || isOfficeBoss
            ? ([
                {
                    title: t("log_page_title"),
                    img: "/static/icons/newspaper.svg",
                    href: "/app/logs",
                },
            ] as CustomButtonI[])
            : []),
        ...(isOfficeBoss
            ? ([
                {
                    title: t("document_menu_title"),
                    img: "/static/icons/document_icon.svg",
                    href: "/app/documents",
                },
            ] as CustomButtonI[])
            : []),
    ];

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <Drawer open={true} onClose={onClose} anchor={"left"}>
            <Box
                sx={{
                    height: "100vh",
                    overflow: "auto",
                    width: {xs: "100%"},
                    minWidth: "390px",
                    maxWidth: {xs: "100%"},
                    bgcolor: "primary.main",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button onClick={onClose}>
                        <img src="/static/icons/close_2.svg" alt=""/>
                    </Button>
                </Box>
                {isAdmin ? null : (
                    <Button
                        onClick={() => {
                            history.push("/app/home");
                            onClose();
                        }}
                    >
                        <img src="/static/icons/project_icon.svg"/>
                        <Typography sx={{color: "common.white", mx: "15px"}}>
                            {t("project_title_button_1")}
                        </Typography>
                    </Button>
                )}
                <Button
                    onClick={() => {
                        history.push("/app/news");
                        onClose();
                    }}
                >
                    <img src="/static/icons/news_icon.svg"/>
                    <Typography sx={{color: "common.white", mx: "15px"}}>
                        {t("project_title_button_3")}
                    </Typography>
                </Button>
                <List
                    sx={{width: "100%", maxWidth: 360, bgcolor: "primary.main"}}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <img src="/static/icons/gestion.svg"/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t("project_title_button_2")}
                            sx={{color: "white"}}
                        />
                        {open ? (
                            <img src="/static/icons/arrow_down.svg"/>
                        ) : (
                            <img src="/static/icons/arrow_down.svg"/>
                        )}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {manageSetting.map((item, index) => (
                            <List component="div" disablePadding>
                                <ListItemButton
                                    sx={{pl: 4}}
                                    onClick={() => {
                                        history.push(item.href);
                                        onClose();
                                    }}
                                >
                                    <ListItemIcon>
                                        <img src={item.img} alt="#"/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{color: "white"}}/>
                                </ListItemButton>
                            </List>
                        ))}
                    </Collapse>
                </List>
            </Box>
        </Drawer>
    );
};
