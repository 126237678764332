import BaseService from './BaseService';
import {login_mock} from "../mocks";
import {UserPagination, UsersRange} from 'store/types/UserTypes';
import UsersServiceMock from 'services/mocks/UsersService.mock';
import {UserUrls} from "./urls";

class AuthService {


  static update_userAvatar = (data : any) => {
    return BaseService.putRequest(UserUrls.UPDATE_USER_AVATAR, data, true);
  };

  static update_password = (data : any) => {
    // return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
  };

  static login = (info: object) => {
    if ((window as any).mocked) {
      return login_mock(info);
    } else {
      // return BaseService.postRequest(AuthUrls.LOGINUSER, info, false);
    }
  };
  static add_new_user = (infos: object) => {
    // return BaseService.postRequest(UserUrls.ADD_NEW_USER, infos, true);
  };
  
  static list_users = (range: UserPagination) => {
    if ((window as any).mocked) {
      return UsersServiceMock.list_users();
    } else {
      return BaseService.getRequest(UserUrls.LIST_USERS(range), true);
    }
  };
  static list_all_users = (range: UserPagination) => {
    if ((window as any).mocked) {
      return UsersServiceMock.list_users();
    } else {
      return BaseService.getRequest(UserUrls.GET_ALL_USER(range), true);
    }
  };

  static list_worker_users = (range: UserPagination) => {
      return BaseService.getRequest(UserUrls.LIST_USERS_WORKER(range), true);
  };

  static update_user = (payload: any) => {
    // return BaseService.putRequest(UserUrls.UPDATE_NEW_USER+payload.user_uuid, payload, true);
  };

  static delete_user = (uuid: string) => {
    // return BaseService.deleteRequest(UserUrls.DELETE_USER(uuid), {}, true);
};
static change_user_status = (payload:any) => {
    // return BaseService.putRequest(UserUrls.CHANGE_USER_STATUS, payload, true);
};

}

export default AuthService;
