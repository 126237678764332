// @ts-ignore
let host_url = global.location.host;
let host_var = 'DEV';

if (host_url === process.env.REACT_APP_PROD_SERVER) {
  host_var = 'PROD';
} else if (host_url === process.env.REACT_APP_DEV_SERVER) {
  host_var = 'DEV';
}

export default {
  env: host_var,
  // stripe_pub_key: process.env[`REACT_APP_${host_var}_STRIPE_PUB_KEY`] || '',
  // paypal_client_id: process.env[`REACT_APP_${host_var}_PAYPAL_CLIENT_ID`] || '',
  api_url: process.env[`REACT_APP_${host_var}_API_URL`],
  // socket_url: process.env[`REACT_APP_${host_var}_SOCKETS_URL`] || '',
  map_key: process.env.REACT_APP_MAP_API_KEY,
  msal_redirect_uri: process.env[`REACT_APP_AZURE_AD_MSAL_REDIRECT_${host_var}_URI`],
};
