import React, { useState, useEffect, useRef } from 'react';
import { ChangePassword, ErrorHandler } from 'components';
import styles from './Application.module.css';
import { Box, IconButton, Popover, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles"
import DashboardHeader from './header/customHeader';
import { useSelector } from "react-redux";
import { ApplicationState } from "store/types";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { useVesionning } from 'views/home/hooks/utilsHook';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) => ({
    topBar: {
        minHeight: '52px',
        paddingTop: '9px',
        paddingBottom: '9px'
    },
    popOver: {
        "& .MuiBackdrop-root": {
            // backdropFilter: "blur(1px)"
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff !important",
            transform: "translateY(-20px) !important",
            borderRadius: "30px",
            padding: "10px 10px 10px 15px",
            width: "310px"
        }
    }
}));

const Application = (props: any) => {
    const { children } = props;
    const { t } = useTranslation();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const classes = useStyles();
    const { user } = useSelector((state: ApplicationState) => state.account);
    const { enqueueSnackbar } = useSnackbar();

    const contentRef = useRef<any>();

    const location = useLocation();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const { data: update_list, isLoading } = useVesionning();

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.scrollTo(0, 0);
        }
    }, [location.pathname]);

    return (
        <div className={styles.root}>
            {
                user?.role?.code === "restricted" &&
                <ChangePassword
                    open={user?.role?.code === "restricted"}
                    onClose={() => {
                    }}
                />
            }
            <ErrorHandler>
                <DashboardHeader className={classes.topBar} onMobileNavOpen={() => setMobileNavOpen(true)} />
            </ErrorHandler>
            <div className={styles.wrapper} >
                <div className={styles.contentContainer}>
                    <div className={clsx(styles.content)} ref={contentRef} onClick={() => handleClose()}>
                        <ErrorHandler>{children}</ErrorHandler>
                    </div>
                    {/*<Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        className={classes.popOver}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className={styles.version_head}>
                            <IconButton sx={{ mr: 0.5 }} onClick={handleClose}>
                                <img src="/static/icons/close_dialog.svg" alt="" />
                            </IconButton>
                        </div>
                        <div className={styles.versionning_content}>
                            <span style={{ "color": "#003594" }}>
                                {t('new_features')}
                            </span>

                            <ul style={{ "margin": "0 15px", "fontSize": "12px" }}>
                                {(update_list?.content?.split("\n") ?? []).map(e => (e && <ReactMarkdown children={e} />))}
                            </ul>
                        </div>
                    </Popover>*/}
                    <div className={clsx(open ? styles.versionning_open_btn : styles.versionning_close_btn)} onClick={(e) => {
                        open ? handleClose() : handleClick(e);
                    }}>
                        {open &&
                            <div className={styles.content_1}>
                                <span style={{ "color": '#456BB5', "fontSize": "9px" }}>
                                    {t('actual_version')}
                                </span>
                                <span style={{ "fontSize": "12px" }}>
                                    V {update_list?.number ?? 0}
                                </span>
                            </div>}
                        {open &&
                            <div className={styles.content_2}>
                                <span >
                                    {t("update_for")} {update_list?.date}
                                </span>
                            </div>}

                        {!open && <Typography color='#fff' style={{ "fontSize": "12px" }}>
                            {update_list?.number ?? 0}
                        </Typography>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Application;
