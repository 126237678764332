// @flow
import * as React from 'react';
import {useState} from 'react';
import {Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, SxProps, Typography} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import {Role, User} from "../../../store/types/UserTypes";
import {useTranslation} from "react-i18next";
import {FormInput} from "../../../components/FormBuilder";
import * as Yup from "yup";
import {ButtonPrimary, FormBuilder} from "../../../components";
import {X} from "react-feather";
import {useSelector} from "react-redux";
import {ApplicationState, Site, SiteData} from "../../../store/types";
import GroupeService from "../../../services/api/GroupeService";
import {useCreateUserMutation, useUpdateUserMutation} from "../hooks";
import {useQueryClient} from 'react-query';

type UserType = User

type AddOrUpdateUserProps = {
    initialState?: UserType
    onClose: () => void,
    site?: Site
};


const AddOrUpdateUser = (props: AddOrUpdateUserProps) => {
    const {onClose} = props;
    const {t} = useTranslation();
    const tablet = useResponsive('down', 'sm');
    const {roles, status} = useSelector((state: ApplicationState) => state.globalData);

    const statusToShow = ["ACTIVED", "UNACTIVED"]
    const staticBossRole: Role[] | undefined = roles?.filter(x => x.code != 'administrator')

    const [loading, setLoading] = useState(false);

    const addMutation = useCreateUserMutation(setLoading, onClose);

    const updateMutation = useUpdateUserMutation(setLoading, onClose);
    const queryClient = useQueryClient();

    const {user} = useSelector((state: ApplicationState) => state.account);
    const [keyword,setKeyword] = useState<string>('')

    const isAdmin = user?.role?.code === 'administrator';
    const isOfficeBoss = user?.role?.code === 'office_boss';

    const handleChange =async (value:string) =>{
        setKeyword(value)
        return   GroupeService.listSites({page: 1, keyword})
    }

    const initialValue = props.initialState ? {
        firstname: props.initialState.firstname,
        lastname: props.initialState.lastname,
        email: props.initialState.email,
        statuse: props.initialState.status,
        role: props.initialState.role?.uuid,
        site: props.initialState?.site || props?.site ? {
            id: props.initialState?.site?.uuid || props?.site?.uuid,
            label: props.initialState?.site?.name || props?.site?.name,
        } : undefined,
    } : {}

    if (props.site) {
        initialValue.site = {
            id: props?.site?.uuid,
            label:props?.site?.name,
        }
    }

    // React.useEffect(() =>{
    //     if(keyword == ''){
    //         handleChange('a')

    //         setTimeout(() => {
    //             handleChange('')
    //         }, 500);
    //     }

    // },[keyword])
    
    const fields: FormInput[] = [
        {
            name: 'firstname',
            fullWidth: true,
            label: t('create_new_user_firstname'),
            type: 'text',
            variant: 'outlined',
            inputProps: {
                margin: 'normal',
            },
            containerProps: {
                xs: 12,
            }
        },
        {
            name: 'lastname',
            fullWidth: true,
            label: t('create_new_user_lastname'),
            type: 'text',
            variant: 'outlined',
            inputProps: {
                margin: 'normal',
            },
            containerProps: {
                xs: 12,
            }
        },
        {
            name: 'email',
            fullWidth: true,
            label: t('create_new_user_email'),
            type: 'email',
            variant: 'outlined',
            inputProps: {
                margin: 'normal',
                disabled: !!props.initialState
            },
            containerProps: {
                xs: 12,
            }
        },
        {
            name: 'role',
            fullWidth: true,
            label: t('create_new_user_role'),
            type: 'select',
            selectOptions: (!isAdmin ? staticBossRole || [] : roles || [])
                .filter(role => role.code !== 'mandant')
                .map(role => ({title: role.name, value: role.uuid})),
            variant: 'outlined',
            inputProps: {
                margin: 'normal',
                disabled: ( (!isAdmin && !isOfficeBoss) && initialValue?.role != null )
            },
            containerProps: {
                xs: 12,
            }
        },

        ...(props.initialState ? [{
            name: 'statuse',
            fullWidth: true,
            label: t('select_status'),
            type: 'select',
            selectOptions: (statusToShow || []).map(value => ({
                title: `${t(`create_new_user_status_${value}`)}`,
                value: value
            })),
            variant: 'outlined',
            inputProps: {
                margin: 'normal',
            },
            containerProps: {
                xs: 12,
            }
        }] as FormInput[] : []),

        ...(isAdmin ? [{
            name: 'site',
            fullWidth: true,
            label: 'create_new_user_site',
            type: 'async_auto_complete',
            asyncAutoCompleteProps: {
                
                onChange:(value:string) =>handleChange(value),
                httpServiceProvider: (value) =>handleChange(value)
                  ,
                mapResultRequestToAutocompleteData: (
                    responseJson: SiteData
                ) =>
                    responseJson.data.map((i) => ({
                        id: i.uuid || 0,
                        label: `${i.name}`
                    })),
                loadingText: t('auto_complete_loading'),
                noOptionText: t('auto_complete_empty_health_personal'),
                label: t('create_new_user_site'),
                
            },
            inputProps: {
                margin: 'normal',
                disabled: initialValue?.site != null && initialValue.email == null
            },
            containerProps: {
                xs: 12
            }
        }] as FormInput[] : []),
    ];


    const validations: Object = {
        firstname: Yup.string().required(t('field_require')),
        role: Yup.string().required(t('field_require')),
        email: Yup.string()
            .email(t('field_require_email'))
            .required(t('field_require')),
    };

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'} fullScreen={tablet ?? false}>

            <CustomDialogTitle title={props?.initialState?.uuid ? t('update_new_user') : t('create_new_user')}
                               onClose={onClose}/>
            <DialogContent>
                <Box sx={{pt: 4}}>
                    {props.site && <Typography sx={{mb: 2}} variant={'body2'}>
                        {t('add_user_description', {
                            website: props.site?.name
                        })}
                    </Typography>}
                    
                    <FormBuilder
                        fields={fields}
                        validations={validations}
                        color={'dark'}
                        initialValues={initialValue}
                        formProps={{
                            noValidate: true
                        }}
                        renderSubmit={(
                            isSubmitting,
                            errors,
                            touched,
                            setFieldTouched: Function,
                            handleSubmit: Function
                        ) => (
                            <Grid item xs={12}>
                                <ButtonPrimary
                                    loading={loading}
                                    sx={{mt: 2}}
                                    fullWidth
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        handleSubmit();
                                        fields.forEach((field) => {
                                            setFieldTouched(field.name, true);
                                        });
                                    }}
                                >
                                    {props?.initialState?.uuid ? t('update_new_user_action')
                                        : t('create_new_user_action')}

                                </ButtonPrimary>
                            </Grid>
                        )}
                        onSubmit={async ({firstname, lastname, site, email, role, statuse}: any) => {
                            let payload: any = {
                                firstname,
                                lastname,
                                role_uuid: role,
                                status: statuse,
                                site_uuid: props.site?.uuid || site?.id
                            }
                            if (props.initialState) {
                                payload.user_uuid = props.initialState.uuid
                                updateMutation.mutate(payload);
                            } else {
                                payload = {...payload, email}
                                addMutation.mutate(payload);
                            }
                        }}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

interface CustomDialogTitleProps {
    title: string
    onClose?: () => void,
    sx?: SxProps
}

export const CustomDialogTitle = (props: CustomDialogTitleProps) =>
    <DialogTitle sx={{
        px: 3,
        py: 1.5,
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: 'primary.main',
        alignItems: 'center',
        color: '#fff',
        ...(props.sx || {})
    }}>
        {props.title}
        {props.onClose && <IconButton onClick={props.onClose} sx={{transform: 'translateX(8px)'}}>
            <X color={'#fff'}/>
        </IconButton>}
    </DialogTitle>

export default AddOrUpdateUser;