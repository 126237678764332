import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LogOut } from 'react-feather';
import { ApplicationState } from "store/types";
import SecureLS from 'secure-ls';
import { ButtonPrimary } from "components";
import { useState } from "react";




const ls = new SecureLS({ encodingType: 'aes' });

export const BannerImpersonate = () => {
    const { user } = useSelector((state: ApplicationState) => state.account);
    const [loading,setLoading] = useState(false)
    const { t } = useTranslation()

    return (
        <Box sx={{ position: 'absolute', display: 'flex', alignItems: 'center', height: '40px', bgcolor: '#D22630', width: '100%', top: '50px', zIndex: '1000' }} >
            <Box sx={{flexGrow:1,ml:'25px'}} >
                <Typography sx={{ color: '#fff',fontSize:'25px' }}>
                    {t('message_impersonate_connected', { user: user?.lastname + ' ' + user?.firstname })}
                </Typography>
            </Box>
            <ButtonPrimary loading={loading} sx={{ width: '30px', height: '30px', bgcolor: '#fff',display:'flex',alignItems:'center',justifyContent:'center',mx:'25px',mt:'5px',cursor:'pointer' }} onClick={() =>{
                let myToken = ls.get('token_2')
                let myUserinfo = ls.get('user_2')
                setLoading(true)

                if(myUserinfo != '' && myToken != ''){
                   ls.set('user',myUserinfo)
                   ls.set('token',myToken) 

                   setTimeout(() => {
                    ls.remove('user_2')
                    ls.remove('token_2')
                    window.location.reload()
                   }, 500);
                }



            }} >
                <LogOut color="#D22630" size={10} />
            </ButtonPrimary>
        </Box>
    )
}