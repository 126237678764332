import { Box } from '@mui/material';
import React, { PureComponent } from 'react';
import styles from './ErrorHandler.module.css';
import { RefreshCcw, AlertTriangle } from "react-feather";
import IconButton from '@mui/material/IconButton';

interface ErrorHandlerProps {
  errorElement?: React.ReactNode;
  onError?: Function;
  children?: React.ReactNode
}

interface ErrorHandlerState {
  hasError: boolean;
}

class ErrorHandler extends PureComponent<ErrorHandlerProps, ErrorHandlerState> {
  state = {
    hasError: false
  };

  static getDerivedStateFromError(error: any) {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    console.log("the global log : ", error)
    const {
      onError = () => {
      }
    } = this.props;
    onError(error, info);
  }

  render() {
    const {
      errorElement = (
        <Box display="flex" justifyContent={"center"} alignItems={"center"} flexDirection="column">
          <Box color={"#D22630"} marginBottom={1.5}>
            <AlertTriangle size={70} />
          </Box>
          <b className={styles.errorMessage}>Es ist ein Fehler aufgetreten<br />bitte versuchen Sie es erneut  </b>
          <Box height={20} />
          <IconButton aria-label="Aktualisieren" color="primary" onClick={() => {
            window.location.reload();
          }}>
            <RefreshCcw />
          </IconButton>
        </Box>
      )
    } = this.props;
    if (this.state.hasError) {
      return errorElement;
    }
    try {
      return <>{this.props.children}</>;
    } catch (error) {
      this.setState({ hasError: true });
      return errorElement;
    }
  }
}

export default ErrorHandler;
