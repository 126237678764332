import BaseService from './BaseService';
import {AuthUrls} from './urls';
import {login_mock} from "../mocks";

class AuthService {

    static get_userInfo = () => {
        return BaseService.postRequest(AuthUrls.GET_CONNECTED_INFO, {}, true);
    };

    static update_userInfo = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_USER_INFO, data, true);
    };

    static update_password = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
    };

    static login = (info: any) => {
        if ((window as any).mocked) {
            return login_mock(info);
        } else {
            return BaseService.postRequest(AuthUrls.LOGIN_USER_OTP, info, false);
        }
    };

    static impersonateLogin = (uuid:string) =>{
        return BaseService.postRequest(AuthUrls.IMPERSONATE(uuid),{},true)
    }

    static loginAzure = (info:any) => {
        return BaseService.postRequest(AuthUrls.LOGIN_WITH_AZURE, info, false);

    }
    static changePassword = (payload: {
        password: string,
        current_password?: string
    }) => {
        return BaseService.putRequest(AuthUrls.USER_CHANGE_PASSWORD(), {
                new_password: payload.password,
                new_password_confirmation: payload.password,
                ...(payload.current_password ? {current_password: payload.current_password} : {})
            }, true
        );
    };

    static confirmLogin = (code: string,id_connected:string,name:string,system:string) => {
        return BaseService.putRequest(AuthUrls.CONFORM_LOGIN_USER_OTP, {code,id_connected,name,system}, false);
    };

    static resendLoginMail = (email: string) => {
        return BaseService.putRequest(AuthUrls.RESEND_LOGIN_MAIL(email), {}, false);
    };

    static sendEmailForResetPassword(email: string) {
        return BaseService.putRequest(
            AuthUrls.SEND_EMAIL_RESET_PWD(email),
            {},
            false
        );
    }

    static sendNewPassword(payload: { email: string, code: string, new_password: string }) {
        return BaseService.putRequest(
            AuthUrls.SEND_NEW_PWD,
            payload,
            false
        );
    }
    static socialLogin(provider: string, token: string) {
        const providers = {
          'azure-ad': AuthUrls.LOGIN_AZURE_AD_URL()
        };

        return BaseService.postRequest(
          // @ts-ignore
          providers[provider],
          {
            [provider === 'azure-ad' ? 'microsoft_token' : `${provider}_token`]:
              token
          },
          false
        );
      }
}

export default AuthService;
