import { User } from 'store/types';

interface AuthResults {
  email: string;
  password: string;
  Authorization: string;
  message: string;
  data: User;
}

export const connected_user: User = {
  "uuid": "a73b1c3f-e81b-4b83-868e-8ae6fcc20780",
  "email": "dongmocarl@gmail.com",
  "firstname": "Carlos",
  "lastname": "Mouafo",
  "status": "ACTIVED",
  "role": {
    "name": "Administrator",
    "code": "customer",
    "uuid": "a59ca376-0f2d-4809-9c2d-516bc9ebc97a"
  },
  "last_login": "2022-05-12T18:09:53.086921",
  "date_added": "2022-05-12T15:31:53.253437+00:00",
  "date_modified": "2022-05-12T17:41:54.027803+00:00",
  "reminders": [],
  "is_favorite": true,
};


export const auth_users: AuthResults[] = [
  {
    Authorization:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjE1ODQyNDEsImlhdCI6MTU5MDA0ODIzNiwic3ViIjoxfQ.Xvo-hJuQyz6I5-Co7Ezs3LFWUYjGmGTTlon1Bm7ANKk',
    message: 'Connexion reussi',
    email: 'admin@gmail.com',
    password: 'string',
    data: connected_user
  }
];

export const list_users: User[] = [];

export const connected_information = {};
