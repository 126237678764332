import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ApplicationState } from 'store/types';
import SecureLS from 'secure-ls';
const ls = new SecureLS({ encodingType: 'aes' });


function AuthGuard(props: any) {
  const { children } = props;
  let token: any;
  try {
    token = ls.get('token');
  } catch (error) {}
  if (!token) {
    return <Redirect to="/auth" />;
  }

  return children;
}

export default AuthGuard;

// import * as React from "react";
// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { useRouter } from "next/router";
// import cookie from "js-cookie";
// import useTranslation from "next-translate/useTranslation";
// import { setTranslationFunction } from "store/actions";
// import { getUserInfo } from "../store/effects/AccountEffect";
// import { loadGlobalDataEffect } from "../store/effects/GlobalDataEffects";
// import { AuthService } from "service/api";

// interface LayoutProps {
//   children: React.ReactNode;
// }

// const AuthGuard = (props: LayoutProps) => {
//   const router = useRouter();
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const token = cookie.get("token") || cookie.get("token_impersonate");
//   if (
//     !token &&
//     typeof window !== "undefined" &&
//     !router.pathname.includes("auth") &&
//     !router.pathname.includes("linkedin") &&
//     !router.pathname.includes("session") &&
//     (!router.pathname.includes("room") || router.pathname === "/setting-room")
//   ) {
//     router.push("/auth/login");
//     return <div />;
//   }
//   useEffect(() => {
//     dispatch(setTranslationFunction(t));
//   }, [dispatch, t]);

//   const type_window = typeof window;

//   useEffect(() => {
//     dispatch(getUserInfo());
//     dispatch(loadGlobalDataEffect());
//   }, [type_window]);

//   return <>{props.children}</>;
// };

// export default AuthGuard;

