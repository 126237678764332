import {useMutation, useQuery, useQueryClient} from "react-query";
import {ClientData, User, UserData} from '../../store/types/UserTypes';
import BaseService from "../../services/api/BaseService";
import {ClientUrls, NotificationUrl, ProjectUrls} from "../../services/api/urls";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {Client} from "store/types/GlobalDataType";
import {Task} from "../../store/types/ProjectType";
import {Document, Notification} from "../../store/types/NotificationType";

export const get_detail_client_key = (uuid: string) => ['clients_details', uuid];
export const get_client_user_key = (uuid: string) => ['clients_users', uuid];
export type ClientCrudType = ['clients', number, string, string, string]
export type ClientHomeCrudType = ['clients_home', number, string, string, string]
export const payload_clients_key = ['clients_current_key'];
export const payload_clients_home_key = ['clients_home_current_key'];
export const default_clients_key: ClientCrudType = ['clients', 1, '', 'date_added', 'desc'];
export const default_clients_home_key: ClientHomeCrudType = ['clients_home', 1, '', 'date_added', 'desc'];
export const datev_clients_list_key = (keyword: string) => ["clients_datev", keyword]


export const useClients = (page: number, keyword: string, order_field: string = 'date_added', home = false, order: string = "desc", favorite?: boolean, invitation?: boolean, isHome2?: boolean) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const current_key = [home ? 'clients_home' : 'clients', page, keyword, order_field, order, favorite, invitation];

    queryClient.setQueryData(home ? payload_clients_home_key : payload_clients_key, current_key);


    return useQuery<ClientData, Error>(
        current_key,
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    (!home ? ClientUrls.READ_CLIENT : isHome2 ? ClientUrls.READ_CLIENT_HOME2 : ClientUrls.READ_CLIENT_HOME)({
                        page: (page as number),
                        keyword: (keyword as string),
                        per_page: 9,
                        order_field,
                        order,
                        favorite,
                        invitation: invitation
                    }),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },{
            staleTime: 10 *(60*100),
            cacheTime:10 * (60*100)
        })

}


export const useHomeTask = () => {
    const {t} = useTranslation();

    return useQuery<Task[], Error>(
        ['home_task'],
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(ClientUrls.HOME_TASK, true);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        }, {
            staleTime: 10 *(60*100),
            cacheTime:10 * (60*100)
        }
    )
}

export const clientProjectsMutation = (
    setLoading: (loading: boolean) => void,
    client_uuid: string,
    isInvitation?: boolean,
    onSuccess?: () => void,
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();


    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.getRequest(ClientUrls.GET_CLIENT_PROJECTS(uuid, isInvitation ?? false), true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {

            onSuccess: (data: any, uuid) => {
                if (onSuccess)
                    onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useNotificationsHome = () => {
    const {t} = useTranslation();

    return useQuery<Notification, Error>(
        ['home_notifications'],
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(NotificationUrl.FETCH_NOTIFICATION, true);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            staleTime: 10 *(60*100),
            cacheTime:10 * (60*100)
        }
    )
}


export const useReadNotificationMutation = () => {


    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (id: number) => {

            let data;
            try {
                const response = await BaseService.getRequest(NotificationUrl.READ_NOTIFICATION(id), true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onSuccess: (_, id) => {
                const key = ['home_notifications'];
                const update_notification = (doc: Document) => {
                    return doc.id === id ? {...doc, read: true} : doc;
                }

                queryClient.setQueryData<Notification | undefined>(key, (old) => {
                    if (!old) {
                        return old
                    }
                    return {
                        ...old,
                        notifications: (old.notifications || []).map(update_notification),
                        messages: (old.messages || []).map(update_notification),
                        documents: (old.documents || []).map(update_notification),
                    };
                });
            },
        }
    )
}


export const useDeleteNotificationMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    return useMutation(
        async (notification_id: number,) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(NotificationUrl.DELETE_NOTIFICATION, {notification_id}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (_, id) => {
                const key = ['home_notifications']

                const delete_notification = (doc: Document) => {
                    return doc.id !== id;
                }

                queryClient.setQueryData<Notification | undefined>(key, (old) => {
                    if (!old) {
                        return old
                    }
                    return {
                        ...old,
                        notifications: (old.notifications || []).filter(delete_notification),
                        messages: (old.messages || []).filter(delete_notification),
                        documents: (old.documents || []).filter(delete_notification),
                    };
                });
                onSuccess();

            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const home_calendar_payload_key = 'home_calendar_payload_key'

export const useHomeCalendarTask = (start_date: string, end_date: string) => {
    const {t} = useTranslation();
    const current_key = ['home_calendar_task', start_date, end_date];
    const queryClient = useQueryClient();
    queryClient.setQueryData(home_calendar_payload_key, current_key);

    return useQuery<Task[], Error>(
        current_key,
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(ClientUrls.HOME_TASK_CALENDAR({
                    start_date,
                    end_date
                }), true);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            staleTime: 10 *(60*100),
            cacheTime:10 * (60*100)
        }
    )
}


export const default_clients: ClientData = {
    pages: 1,
    data: [],
    current_page: 1,
    total: 10
}

export const useCreateClientMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void,
    isDatev?: boolean
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const key = queryClient.getQueryData<ClientCrudType>(payload_clients_key) || default_clients_key;

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(isDatev ? ClientUrls.ADD_DATEV_CLIENT : ClientUrls.CREATE_CLIENT, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Client) => {
                if (key)
                    queryClient.setQueryData<ClientData>(key, (old) => {
                        const clients = old || default_clients;

                        return {
                            ...clients,
                            data: [data, ...(clients.data || []),]
                        }
                    });

                enqueueSnackbar(t('client_create_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useUpdateClientMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(ClientUrls.UPDATE_CLIENT, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Client) => {

                const client = queryClient.getQueryData<Client>(get_detail_client_key(data.uuid ?? '-'));
                if (client) {
                    queryClient.setQueryData<Client>(get_detail_client_key(data.uuid ?? ''), {...client, ...data});
                }
                const key = queryClient.getQueryData<ClientCrudType>(payload_clients_key) || default_clients_key;
                if (key)
                    queryClient.setQueryData<ClientData>(key, (old) => {
                        const clients = old || default_clients;

                        return {
                            ...clients,
                            data: [...(old?.data || [])].map(client => client.uuid === data.uuid ? data : client)
                        }
                    });

                enqueueSnackbar(t('client_update_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useToggleFavoriteClient = (
    current_key: any[],
    // setLoading: (loading: boolean) => void,
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async ({client_uuids, mode}: { client_uuids: string[], mode: 'add' | 'delete' }) => {
            // setLoading(true);
            let data;
            try {
                const response = await (mode == "add" ?
                    BaseService.postRequest : BaseService.deleteRequest)(mode == 'add' ? ClientUrls.ADD_CLIENT_IN_FAVORITES : ClientUrls.DELETE_CLIENT_IN_FAVORITES, client_uuids, true)
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                // enqueueSnackbar(error.message, {
                //   variant: 'warning'
                // })
            },
            onSuccess: (data: Client) => {

                queryClient.invalidateQueries({queryKey: current_key})

                // enqueueSnackbar(t('users_updated_success'), {
                //   variant: 'success'
                // });
            },
            onSettled: () => {
                // setLoading(false)
            }
        }
    )
}

export const useToggleFavoriteProjects = (
    current_key: any[],
    onSuccess?: () => void
    // setLoading: (loading: boolean) => void,
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async ({client_uuids, mode, onSuccess}: { client_uuids: string[], mode: 'add' | 'delete', onSuccess?: () => void }) => {
            // setLoading(true);
            let data;
            try {
                const response = await (mode == "add" ?
                    BaseService.postRequest : BaseService.deleteRequest)(mode == 'add' ? ProjectUrls.ADD_PROJECTS_IN_FAVORIES : ProjectUrls.DELETE_PROJECTS_IN_FAVORITES, client_uuids, true)
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                  variant: 'warning'
                })
            },
            onSuccess: (data: Client) => {

                queryClient.invalidateQueries({queryKey: current_key})

                // enqueueSnackbar(t('added_to_favorites'), {
                //   variant: 'success'
                // });
                if (onSuccess)
                    onSuccess()
            },
            onSettled: () => {
                // setLoading(false)
            }
        }
    )
}

export const useToggleUserClient = (
    setLoading: (loading: boolean) => void,
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async ({client_uuid, mode, user_uuid}: { client_uuid: string, user_uuid: string, mode: 'add' | 'delete' }) => {
            setLoading(true);
            let data;
            try {
                const response = await (mode === 'add' ?
                    BaseService.putRequest : BaseService.deleteRequest)
                (mode === 'add' ? ClientUrls.ADD_USER_VISIBLITY_URL(client_uuid) : ClientUrls.DELETE_USER_VISIBLITY_URL(client_uuid, user_uuid), mode === 'add' ? [user_uuid] : {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: Client) => {

                const client = queryClient.getQueryData<Client>(get_detail_client_key(data.uuid ?? '-'));
                if (client) {
                    queryClient.setQueryData<Client>(get_detail_client_key(data.uuid ?? ''), {...client, ...data});
                }
                const key = queryClient.getQueryData<ClientCrudType>(payload_clients_key) || default_clients_key;

                if (key)
                    queryClient.setQueryData<ClientData>(key, (old) => {
                        const clients = old || default_clients;

                        return {
                            ...clients,
                            data: [...(old?.data || [])].map(client => client.uuid === data.uuid ? data : client)
                        }
                    });

                enqueueSnackbar(t('users_updated_success'), {
                    variant: 'success'
                });
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useDeleteClientMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const key = queryClient.getQueryData<ClientCrudType>(payload_clients_key) || default_clients_key;

    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(ClientUrls.DELETE_CLIENT(uuid), {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return {uuid};
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {
                if (key)
                    queryClient.setQueryData<UserData>(key, (old) => {
                        const clients = old || default_clients;

                        return {
                            ...clients,
                            data: [...(old?.data || [])].filter(client => client.uuid !== data.uuid)
                        }
                    });

                enqueueSnackbar(t('user_delete_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useClientDetails = (uuid: string) => {
    const {t} = useTranslation();

    return useQuery<Client, Error>(
        get_detail_client_key(uuid),
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            if (!uuid)
                throw new Error(t('UUID not found'))
            try {
                const response = await BaseService.getRequest(
                    ClientUrls.READ_CLIENT_UUID(uuid),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

export const useClientUser = (uuid: string, page: number, keyword: string, order_field: string = 'date_added',) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();


    return useQuery<ClientData, Error>(
        get_client_user_key(uuid),
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    (ClientUrls.READ_USER_CLIENT_UUID)({
                        page: (page as number),
                        keyword: (keyword as string),
                        per_page: 100,
                        order_field
                    }, uuid),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

export const useCreateClientUserMutation = (
    setLoading: (loading: boolean) => void,
    uuid: string,
    client_uuid: string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(ClientUrls.CREATE_USER_CLIENT(uuid), values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: User) => {
                const current_key = get_client_user_key(uuid);

                queryClient.setQueryData<ClientData | undefined>(current_key, (old) => {
                    const users = old || default_clients;

                    return {
                        ...users,
                        data: [data, ...(users.data || []),]
                    }
                });


                enqueueSnackbar(t('client_create_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useAddExistingUserToClientMutation = (
    setLoading: (loading: boolean) => void,
    uuid: string,
    client_uuid: string,
    onSuccess: () => void
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(ClientUrls.ADD_EXISTING_USER_TO_CLIENT(uuid), values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: User[]) => {
                const current_key = get_client_user_key(uuid);

                queryClient.setQueryData<ClientData | undefined>(current_key, (old) => {
                    const users = old || default_clients;

                    const dataUser = data.forEach((elet) => {
                        users.data.push(elet)
                    })

                    return {
                        ...users,
                        data: [...(users.data || [])]
                    }
                });


                enqueueSnackbar(t('client_create_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useUpdateClientUserMutation = (
    setLoading: (loading: boolean) => void,
    uuid: string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(ClientUrls.UPDATE_USER_CLIENT(uuid), values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: User) => {
                const current_key = get_client_user_key(uuid);
                queryClient.setQueryData<ClientData>(current_key, (old) => {
                    const users = old || default_clients;

                    return {
                        ...users,
                        data: [...(old?.data || [])].map(users => users.uuid === data.uuid ? data : users)
                    }
                });

                enqueueSnackbar(t('client_update_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useDeleteClientUserMutation = (
    setLoading: (loading: boolean) => void,
    uuid: string,
    user_uuid: string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();
    const client_uuid = uuid

    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(ClientUrls.DELETE_USER_CLIENT(client_uuid, user_uuid), {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return {uuid};
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {
                const current_key = get_client_user_key(uuid);
                queryClient.setQueryData<UserData>(current_key, (old) => {
                    const clients = old || default_clients;

                    return {
                        ...clients,
                        data: [...(old?.data || [])].filter(client => client.uuid !== data.uuid)
                    }
                });

                enqueueSnackbar(t('user_delete_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useDeleteProjectMutation = (
    setLoading: Function,
    client_uuid: string,
    onSuccess: () => void
) => {

    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            const current_key = ['client_project', '',]
            try {
                const response = await BaseService.putRequest(ProjectUrls.UPDATE_STATUS, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    await queryClient.invalidateQueries(current_key)
                    return data?.uuid ?? "";
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (uuid: string) => {
                const key = queryClient.getQueryData<ClientHomeCrudType>(payload_clients_home_key) || default_clients_home_key;

                queryClient.setQueryData<ClientData | undefined>(key, (old) => {
                    const clients = old || default_clients;
                    let ourClient: Client | undefined = [...clients.data].find(item => item.uuid === client_uuid);
                    
                    const ourClientProject = {
                        ...ourClient,
                        projects: (ourClient?.projects ?? []).filter(item => item.uuid !== uuid)
                    }


                    if (ourClient) {
                        return {
                            ...clients,
                            data: [...(clients.data)].map(item => item.uuid === client_uuid ? ourClientProject : item),
                        }
                    }

                    return clients;
                })

                enqueueSnackbar(t('delete_project_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useQueryDatevCleintsList = (setLoading: Function) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    return useMutation(
        async (keyword: string) => {
            setLoading(true)
            let data: any;
            try {
                const response = await BaseService.getRequest(ClientUrls.GET_DATEV_CLIENT_LIST(keyword), true);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data) => {
                return data
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

