import {useTranslation} from "react-i18next";
import {useMutation, useQuery, useQueryClient} from "react-query";
import BaseService from "../../services/api/BaseService";
import {SafeZoneUrl} from "../../services/api/urls";
import {SafeZoneDetail, SafeZoneFolder} from "../../store/types/SafeZoneType";
import {useSnackbar} from "notistack";

export const safe_zone_key = ['safe_zone_folder'];
export const payload_safe_zone_files = ['payload_safe_zone_files'];

export const useSafeZoneFile = (page: number, keyword: string, safe_setting_uuid: string = 'date_added',safe_token:string) => {
    const {t} = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['safe_zone_files', page, keyword, safe_setting_uuid,safe_token];
    queryClient.setQueryData(payload_safe_zone_files, current_key);


    return useQuery<SafeZoneDetail, Error>(
        current_key,
        async () => {
            let data: any;
            try {
                const response = await BaseService.getRequest(
                    SafeZoneUrl.GET_FILES({
                        page,
                        keyword,
                        safe_setting_uuid,
                        safe_token
                    }),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

export interface AddFilePayload {
    uuid?: string;
    file_name?: string;
    token?:string;
    file?: string | any;
}


export const useAddFileInFolderMutation = (
    setLoading: (loading: boolean) => void,
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const key: any = queryClient.getQueryData(payload_safe_zone_files);

    return useMutation(
        async (values: AddFilePayload) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(SafeZoneUrl.ADD_FILE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (response) => {
                if (key)
                    queryClient.setQueryData<SafeZoneDetail | undefined>(key, (old) => {
                        if (!old)
                            return old;
                        return {
                            ...old,
                            data: [response, ...(old.data || [])]
                        }
                    });
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useAskSafeZoneMutation = (
    setLoading: (loading: boolean) => void,
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async () => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(SafeZoneUrl.ASKED_SAFE_ZONE, {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (response) => {
               enqueueSnackbar(response.message, {
                variant:'success'
               })
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}
export const useAcceptedSafeZoneMutation = (
    setLoading: (loading: boolean) => void,
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    return useMutation(
        async (values:any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(SafeZoneUrl.ACCEPTED_SAFE_ZONE(values.uuid,values.give_authorization) , {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (response) => {
               enqueueSnackbar(response.message, {
                variant:'success'
               })
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}



export const useUpdateFileInFolderMutation = (
    setLoading: (loading: boolean) => void,
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const key: any = queryClient.getQueryData(payload_safe_zone_files);

    return useMutation(
        async (values: AddFilePayload) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(SafeZoneUrl.UPDATE_FILE, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (response) => {
                if (key)
                    queryClient.setQueryData<SafeZoneDetail | undefined>(key, (old) => {
                        if (!old)
                            return old;
                        return {
                            ...old,
                            data: (old.data || []).map(f => f.uuid === response.uuid ? response : f)
                        }
                    });
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useDeleteFileInFolderMutation = (
    token:string,
    setLoading: (loading: boolean) => void,
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const key: any = queryClient.getQueryData(payload_safe_zone_files);

    return useMutation(
        async ({uuid}: { uuid: string }) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(SafeZoneUrl.DELETE_FILE(uuid,token), {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (_, {uuid}) => {
                if (key)
                    queryClient.setQueryData<SafeZoneDetail | undefined>(key, (old) => {
                        if (!old)
                            return old;
                        return {
                            ...old,
                            data: (old.data || []).filter(f => f.uuid !== uuid)
                        }
                    });
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}




export const useSafeZoneFolder = (token:string) => {
    const {t} = useTranslation();

    return useQuery<SafeZoneFolder[], Error>(
        safe_zone_key,
        async ({pageParam, queryKey: [key, page]}) => {
            let data: any;
            try {
                const response = await BaseService.getRequest(SafeZoneUrl.FETCH_FOLDERS(token), true);

                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

interface UpdateProps {
    "uuid"?: string,
    "is_active"?: boolean
}


export const useUpdateSafeZoneFolder = (
    setLoading: (loading: boolean) => void,
    token:string
) => {
    const {enqueueSnackbar} = useSnackbar();
    const queryClient = useQueryClient();
    const {t} = useTranslation();


    return useMutation(
        async (values: UpdateProps[]) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(SafeZoneUrl.UPDATE_FOLDER(token), values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (_, values) => {

                queryClient.setQueryData<SafeZoneFolder[] | undefined>(safe_zone_key, (old) => {
                    if (!old)
                        return old;

                    return old.map((item) => {
                        const v = values.find(u => u.uuid === item.uuid);
                        if (v)
                            return ({...item, ...v})
                        return item
                    })
                });
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}
