// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Document } from "../../../store/types/NotificationType";
import { Box, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { ButtonPrimary } from "../../../components";
import { CustomDialogTitle } from "../../users/Components/AddOrUpdateUser";
import { useDeleteNotificationMutation, useReadNotificationMutation } from "../../clients/clientHooks";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useAcceptedSafeZoneMutation } from 'views/SafeZone/safeZoneHook';
import { LoadingButton } from '@mui/lab';
import { RemplaceRegFunction } from '../widgets/RemplaceRegFunction';
import { regExg } from '../hooks/utilsHook';

type Props = {
    notification: Document;
    onClose: () => void
};

const NotificationDetail = (props: Props) => {
    const { onClose } = props;
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const deleteMutation = useDeleteNotificationMutation(setLoading, onClose);
    const readMutation = useReadNotificationMutation();
    const useSafeZoneMutation = useAcceptedSafeZoneMutation((status: boolean) => setLoading(status))

    useEffect(() => {
        if (!props.notification.read) {
            readMutation.mutate(props.notification.id || 0);
        }
    }, []);

    const urlVerify = (text: string) => {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + '</a>';
        })
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth={'sm'} >
            <CustomDialogTitle title={props.notification?.payload_json?.title ?? ''} onClose={onClose} />
            <DialogContent>
                <Box sx={{ pt: 4 }}>
                    {props.notification?.payload_json?.file && <Typography
                        sx={{ cursor: 'pointer' }}
                        color={'secondary'}
                        onClick={() => {
                            window.open(props.notification?.payload_json?.file?.url, '_blanc');
                        }}
                        variant={'body1'} gutterBottom>
                        {props.notification?.payload_json?.file?.file_name}
                        {props.notification.id}
                    </Typography>}
                    {
                        // (props.notification?.payload_json?.title_type === undefined || props.notification?.payload_json?.title_type !== "SAFE_ZONE_ACCESS")
                        // && 
                        (props.notification?.payload_json?.content?.match(regExg) ?? []).length > 0 ? <RemplaceRegFunction text={props.notification?.payload_json?.content as string} /> :  <Typography component={'p'} variant={'body1'} dangerouslySetInnerHTML={{ __html: urlVerify(props.notification?.payload_json?.content as string) }} gutterBottom />
                    }
                    <Typography variant={'caption'}
                        sx={{ color: 'text.grey' }}
                        lineHeight={'18px'}
                        component={'p'}>
                        {moment(props.notification.date_added).format('LLLL')}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonPrimary
                    onClick={() => {
                        deleteMutation.mutate(props.notification.id ?? 0);
                    }}
                    variant={'contained'}
                    color={'error'}
                    loading={loading}>
                    {t('create_new_user_status_DELETED')}
                </ButtonPrimary>
                {(props.notification?.payload_json?.title_type !== undefined && props.notification?.payload_json?.title_type === "SAFE_ZONE_ACCESS") &&
                    <>
                        <LoadingButton onClick={() => {
                            useSafeZoneMutation.mutate({ uuid: props.notification?.payload_json?.uuid, give_authorization: 'ACCEPTED' });
                            props.onClose()
                        }} sx={{
                            height: '42px', width: '230px', bgcolor: '#2DBE20', '&:hover': {
                                bgcolor: 'primary.darker'
                            }
                        }} >
                            <Typography sx={{ color: 'common.white' }} >
                                {t('accepted_request_button')}
                            </Typography>
                        </LoadingButton>
                        <LoadingButton onClick={() => {
                            useSafeZoneMutation.mutate({ uuid: props.notification?.payload_json?.uuid, give_authorization: 'REFUSED' });
                            props.onClose()
                        }} sx={{
                            height: '42px', bgcolor: 'primary.main', '&:hover': {
                                bgcolor: '#B72136'
                            }, width: '130px'
                        }} >
                            <Typography sx={{ color: 'common.white' }}>
                                {t('refused_button')}
                            </Typography>
                        </LoadingButton>
                    </>
                }

            </DialogActions>
        </Dialog>
    );
};

export default NotificationDetail