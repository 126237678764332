// @flow
import * as React from "react";
import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useResponsive from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { FormInput } from "components/FormBuilder";
import * as Yup from "yup";
import { ButtonPrimary, FormBuilder } from "components";
import { useSnackbar } from "notistack";
import { changePasswordEffect } from "store/effects";
import { useDispatch } from "react-redux";

type ChangePasswordProps = {
  onClose: () => void;
  open: boolean;
  isUserUpdate?: boolean;
};

const ChangePassword = (props: ChangePasswordProps) => {
  const { onClose, open, isUserUpdate } = props;
  const { t } = useTranslation();
  const tablet = useResponsive("down", "sm");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [passowrdShow, setPasswordShow] = useState<string[]>([])
 const handleClickShowPassword = (value:string) => {
    passowrdShow.includes(value) ? setPasswordShow([]) : setPasswordShow([value])

  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);

  const fields: FormInput[] = [
    // @ts-ignore
    ...(isUserUpdate
      ? ([
        {
          name: "current_password",
          fullWidth: true,
          autoFocus: true,
          label: t("label_current_password"),
          type:  passowrdShow.includes('1') ? 'text' : 'password',
          variant: "outlined",
          inputProps: {
            margin: "normal",
            InputProps: {
              endAdornment: <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>handleClickShowPassword('1')}
                  sx={{ width: '18px', height: '18px', p: '0' }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passowrdShow.includes('1') ? <img src="/static/icons/eye_open_blue.svg" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    marginBottom: '0'
                  }} alt="" /> : <img src="/static/icons/eye_close_blue.svg" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    marginBottom: '0'
                  }} alt="" />}
                </IconButton>
              </InputAdornment>,
            },
          },
          containerProps: {
            xs: 12,
          },
        }
      ] as FormInput[])
      : []),
    {
      name: "password",
      fullWidth: true,
      autoFocus: !isUserUpdate,
      label: t("create_new_user_password"),
      type:  passowrdShow.includes('2') ? 'text' : 'password',
      variant: "outlined",
      containerProps: {
        xs: 12,
      },
      inputProps: {
        margin: "normal",
        InputProps: {
          endAdornment: <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=>handleClickShowPassword('2')}
              sx={{ width: '18px', height: '18px', p: '0' }}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passowrdShow.includes('2') ? <img src="/static/icons/eye_open_blue.svg" style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                marginBottom: '0'
              }} alt="" /> : <img src="/static/icons/eye_close_blue.svg" style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                marginBottom: '0'
              }} alt="" />}
            </IconButton>
          </InputAdornment>,
        },
      },
    },
    {
      name: "confirm_password",
      fullWidth: true,
      label: t("login_form_password_new_confirmation"),
      type:  passowrdShow.includes('3') ? 'text' : 'password',
      variant: "outlined",
     
      containerProps: {
        xs: 12,
      },
      inputProps: {
        margin: "normal",
        InputProps: {
          endAdornment: <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={()=>handleClickShowPassword('3')}
              sx={{ width: '18px', height: '18px', p: '0' }}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passowrdShow.includes('3') ? <img src="/static/icons/eye_open_blue.svg" style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                marginBottom: '0'
              }} alt="" /> : <img src="/static/icons/eye_close_blue.svg" style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                marginBottom: '0'
              }} alt="" />}
            </IconButton>
          </InputAdornment>,
        },
      },
    },
  ];

  const validations: Object = {
    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&+=\* \\.-/¨,])(?=.{8,})/, t('strong_password_error')).required(t("field_require")),
    confirm_password: Yup.string()
      .required(t("field_require"))
      .oneOf([Yup.ref("password"), null], t("label_password_is_not_same")),
    ...(isUserUpdate
      ? { current_password: Yup.string().required(t("field_require")) }
      : {}),
  };

  const initialValue = {
    password: "",
    confirm_password: "",
    current_password: "",
  };
  const theme: Theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      fullScreen={matches}
    >
      <CustomDialogTitle
        onClose={onClose}
        isCanClose={isUserUpdate}
        title={t("modal_change_password_title")}
      />
      <DialogContent>
        <Box sx={{ pt: 4 }}>
          {!isUserUpdate && (
            <Typography sx={{ mb: 2 }} variant={"body2"}>
              {t("modal_change_password_description")}
            </Typography>
          )}

          <FormBuilder
            fields={fields}
            validations={validations}
            color={"dark"}
            initialValues={initialValue}
            formProps={{
              noValidate: true,
            }}
            renderSubmit={(
              isSubmitting,
              errors,
              touched,
              setFieldTouched: Function,
              handleSubmit: Function
            ) => (
              <Grid item xs={12}>
                <ButtonPrimary
                  loading={loading}
                  sx={{ mt: 2 }}
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => {
                    handleSubmit();
                    fields.forEach((field) => {
                      setFieldTouched(field.name, true);
                    });
                  }}
                >
                  {t("save_task_update")}
                </ButtonPrimary>
              </Grid>
            )}
            onSubmit={async (values: any) => {
              dispatch(
                changePasswordEffect(
                  {
                    password: values.password,
                    current_password: isUserUpdate
                      ? values.current_password
                      : undefined,
                  },
                  setLoading,
                  enqueueSnackbar,
                  () => {
                    if (isUserUpdate) {
                      onClose();
                    }
                  }
                )
              );
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

interface CustomDialogTitleProps {
  title: string;
  onClose: () => void;
  isCanClose?: boolean;
  sx?: SxProps;
}

export const CustomDialogTitle = (props: CustomDialogTitleProps) => (
  <DialogTitle
    sx={{
      px: 3,
      py: 1.5,
      display: "flex",
      justifyContent: "space-between",
      bgcolor: "primary.main",
      alignItems: "center",
      color: "#fff",
      ...(props.sx || {}),
    }}
  >
    {props.title}
    {props.isCanClose && props.onClose && (
      <IconButton onClick={props.onClose} sx={{ transform: "translateX(8px)", color: "#ffffff" }}>
        <Typography>
          X
        </Typography>
      </IconButton>
    )}
  </DialogTitle>
);

export default ChangePassword;
