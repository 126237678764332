import {createContext, FC, PropsWithChildren, ReactNode, useMemo, useState} from "react";
import {GroupActionPayload, TaskGroupData, TaskList} from "../../store/types/TaskType";
import {Task} from "../../store/types/ProjectType";
import {ImportDocumentDatev} from "../documents/widgets/CardDocument";

type ProjectContextProviderProps = {
    children: ReactNode
}
export type SelectedDocumentTypeProps = {
    task_uuid: string, documents_uuids: string[], task_group_uuid: string
}

export interface IProjectContext {
    project_uuid?: string,
    setProjectUuid: (value?: string) => void,
    task_uuid?: string,
    setTaskUuid: (value?: string) => void,
    all_tasks: string[],
    all_tasks_group: string[],
    all_groups_with_childs: { [p: string]: string[] },
    all_groups_with_his_childs: { [p: string]: string[] },
    projectData?: TaskList[],
    setProjectData: (value: TaskList[]) => void,
    globalDataProject?: TaskGroupData,
    setGlobalDataProject: (value?: TaskGroupData) => void,
    actionPayload: GroupActionPayload,
    setActionPayload: (value: GroupActionPayload) => void,
    checkedTasks: string[],
    setCheckedTasks: (value: string[]) => void,
    checkedForwardTasks: string[],
    setCheckedForwardTasks: (value: string[]) => void,
    checkedTasksGroups: string[],
    setCheckedTasksGroups: (value: string[]) => void,
    checkedForwardTasksGroups: string[],
    setCheckedForwardTasksGroups: (value: string[]) => void,
    isDragging?: string,
    setIsDragging: (value: string) => void
    selectedDocument: SelectedDocumentTypeProps[],
    isMasked?: boolean,
    setSelectedDocument: (value: SelectedDocumentTypeProps[]) => void,
    setIsMasked: (value: boolean) => void,
    lockTaskMenu: { task?: Task; taskList?: TaskList; target: HTMLButtonElement } | undefined,
    setLockTaskMenu: (value: { task?: Task; taskList?: TaskList; target: HTMLButtonElement } | undefined) => void,
    setAllChecked: (value: string) => void,
    documentDatevSelect: string[],
    setDocumentDatevSelect: (value: string[]) => void
    client_uuid: string,
    setClientUuid: (value: string) => void,
    datevImportDocument?: ImportDocumentDatev[]
    setDatevImportDocument: (value: ImportDocumentDatev[]) =>void
}

export const ProjectContext = createContext<IProjectContext>({
    setProjectUuid: () => {
    },
    project_uuid: '',
    setTaskUuid: () => {
    },
    task_uuid: '',
    all_tasks: [],
    all_tasks_group: [],
    all_groups_with_childs: {},
    all_groups_with_his_childs: {},
    projectData: [],
    setProjectData: () => {
    },
    globalDataProject: undefined,
    actionPayload: {
        group_tasks: [],
        tasks: [],
        group_sub_tasks: [],
        users: [],
        deleted: false,
        download: false,
    },
    setActionPayload: () => {
    },
    checkedTasks: [],
    setCheckedTasks: () => {
    },
    checkedForwardTasks: [],
    setCheckedForwardTasks: () => {
    },
    checkedTasksGroups: [],
    setCheckedTasksGroups: () => {
    },
    checkedForwardTasksGroups: [],
    setCheckedForwardTasksGroups: () => {
    },
    setGlobalDataProject: () => {
    },
    isDragging: '',
    setIsDragging: () => {
    },
    setIsMasked: () => {
    },
    isMasked: false,
    lockTaskMenu: undefined,
    setLockTaskMenu: () => {
    },
    setAllChecked: () => {
    },
    selectedDocument: [],
    setSelectedDocument: () => {
    },
    documentDatevSelect: [],
    setDocumentDatevSelect: () => {
    },
    client_uuid: '',
    setClientUuid: () => {
    },
    datevImportDocument:undefined,
    setDatevImportDocument:()=>{}
})

export const ProjectContextProvider: FC<PropsWithChildren<ProjectContextProviderProps>> = ({children}) => {
    const [project_uuid, setProjectUuid] = useState<string | undefined>('')
    const [task_uuid, setTaskUuid] = useState<string | undefined>('')
    const [projectData, setProjectData] = useState<TaskList[] | undefined>([])
    const [globalDataProject, setGlobalDataProject] = useState<TaskGroupData | undefined>(undefined)
    const [checkedTasks, setCheckedTasks] = useState<string[]>([]);
    const [checkedForwardTasks, setCheckedForwardTasks] = useState<string[]>([]);
    const [checkedTasksGroups, setCheckedTasksGroups] = useState<string[]>([]);
    const [checkedForwardTasksGroups, setCheckedForwardTasksGroups] = useState<string[]>([]);
    const [documentDatevSelect, setDocumentDatevSelect] = useState<string[]>([]);
    const [isDragging, setIsDragging] = useState<string>('')
    const [isMasked, setIsMasked] = useState<boolean>(false)
    const [client_uuid, setClientUuid] = useState<string>('')
    const [selectedDocument, setSelectedDocument] = useState<SelectedDocumentTypeProps[]>([])
    const [datevImportDocument, setDatevImportDocument] = useState<ImportDocumentDatev[] | undefined>(undefined)
    const [lockTaskMenu, setLockTaskMenu] = useState<
        { task?: Task; taskList?: TaskList; target: HTMLButtonElement } | undefined
    >();
    const [actionPayload, setActionPayload] = useState<GroupActionPayload>({
        group_tasks: [],
        tasks: [],
        group_sub_tasks: [],
        users: [],
        deleted: false,
        download: false,
    });
    const all_tasks = useMemo(() => {
        const getGroups = (childrens: TaskList[]) => {
            let sub_groups: string[] = [];
            childrens.forEach((group) => {
                sub_groups = [
                    ...sub_groups,
                    ...(group.tasks ?? []).map((task) => task.uuid ?? ""),
                    ...getGroups(group.children ?? []),
                ];
            });
            return sub_groups;
        };
        let sub_groups = getGroups(projectData ?? []);

        return [...sub_groups];
    }, [projectData ?? []]);

    const all_tasks_group = useMemo(() => {
        const getGroups = (childrens: TaskList[]) => {
            let sub_groups: string[] = [];
            childrens.forEach((group) => {
                sub_groups = [
                    ...sub_groups,
                    group.uuid ?? "",
                    ...getGroups(group.children ?? []),
                ];
            });
            return sub_groups;
        };
        let sub_groups = getGroups(projectData ?? []);

        return sub_groups;
    }, [projectData ?? []]);
    const all_groups_with_childs = useMemo(() => {
        const getGroups = (children: TaskList[]) => {
            let groups: { [key: string]: string[] } = {};
            let tasks: string[] = [];

            children.map((group: TaskList) => {
                let response = getGroups(group.children ?? []);
                groups[group.uuid ?? ""] = [
                    ...(group.tasks ?? []).map((task) => task.uuid ?? ""),
                    ...response.tasks,
                ];
                groups = {...groups, ...response.groups};
                tasks = [...tasks, ...groups[group.uuid ?? ""]];
            });

            return {groups, tasks};
        };

        return getGroups(projectData ?? [])["groups"];
    }, [projectData ?? []]);
    const all_groups_with_his_childs = useMemo(() => {
        const getGroups = (children: TaskList[]) => {
            let groups: { [key: string]: string[] } = {};

            children.map((group: TaskList) => {
                let response = getGroups(group.children ?? []);
                groups[group.uuid ?? ""] = [
                    group.uuid ?? "",
                    ...Object.keys(response.groups),
                ];
                groups = {...groups, ...response.groups};
            });

            return {groups};
        };

        return getGroups(projectData ?? [])["groups"];
    }, [projectData ?? []]);
    const setAllChecked = (type: string) => {
        if (type == 'forward') {
            if (checkedForwardTasks.length != 0 || checkedForwardTasksGroups.length != 0) {
                setCheckedForwardTasks([])
                setCheckedForwardTasksGroups([])
            } else {
                setCheckedForwardTasks((prev) => prev.length != all_tasks.length ? all_tasks : [])
                setCheckedForwardTasksGroups((prev) => prev.length !== all_tasks_group.length ? all_tasks_group : [])
            }
        } else {
            setCheckedTasks([])
            setCheckedTasksGroups([])
            if (checkedTasks.length != 0 || checkedTasksGroups.length != 0) {
                setCheckedTasks([])
                setCheckedTasksGroups([])
            } else {
                setCheckedTasks((prev) =>
                    prev.length !== all_tasks.length ? all_tasks : []
                );
                setCheckedTasksGroups((prev) =>
                    prev.length !== all_tasks_group.length ? all_tasks_group : []
                );
            }
        }
    };
    const context = {
        project_uuid,
        setProjectUuid,
        task_uuid,
        setTaskUuid,
        all_tasks,
        all_tasks_group,
        projectData,
        setProjectData,
        setActionPayload,
        actionPayload,
        checkedTasks,
        setCheckedTasks,
        checkedForwardTasks,
        setCheckedForwardTasks,
        checkedTasksGroups,
        setCheckedTasksGroups,
        checkedForwardTasksGroups,
        setCheckedForwardTasksGroups,
        all_groups_with_childs,
        all_groups_with_his_childs,
        globalDataProject,
        setGlobalDataProject,
        isDragging,
        setIsDragging,
        setIsMasked,
        isMasked,
        lockTaskMenu,
        setLockTaskMenu,
        setAllChecked,
        selectedDocument,
        setSelectedDocument,
        setDocumentDatevSelect,
        documentDatevSelect,
        client_uuid,
        setClientUuid,
        setDatevImportDocument,
        datevImportDocument
    }
    return (
        <ProjectContext.Provider value={context}>
            {children}
        </ProjectContext.Provider>
    )
}
