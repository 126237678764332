import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';
import {ReactSVG} from "react-svg";

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR
    // const logo = '/logo/logo_single.svg';

    const logo = (
        <Box sx={{width: 70, height: 31, display: 'flex', alignItems: 'center', ...sx}}>
            {/*<svg width="70" height="31" viewBox="0 0 70 31" fill="none">*/}
            {/*    <path d="M22.0282 30.1742C22.0282 29.9616 22.0282 29.7267 22.0282 29.4918C22.0282 21.8866 22.0317 14.2797 22.0195 6.67443C22.0195 6.20639 22.143 5.85836 22.463 5.50347C23.9831 3.82161 25.4805 2.11917 26.9658 0.408167C27.2823 0.0429929 27.611 -0.0855903 28.1258 0.0995687C28.1258 3.76846 28.1258 7.45107 28.1258 11.132C28.1988 11.1834 28.2736 11.2365 28.3467 11.288C28.4406 11.1234 28.5084 10.9382 28.6302 10.7994C31.6598 7.36877 34.6999 3.94676 37.7191 0.507604C38.0409 0.140715 38.3644 -0.00501215 38.8548 0.000131159C41.0323 0.0258477 43.2097 0.0104187 45.3872 0.0104187C45.5802 0.0104187 45.775 0.0104187 46.1542 0.0104187C42.1262 4.62568 38.2044 9.11921 34.2703 13.6282C37.9539 17.7702 41.5923 21.8609 45.2724 25.9978C44.0445 27.3848 42.8619 28.7683 41.6131 30.0953C41.281 30.4485 40.8166 30.3251 40.4688 29.9256C38.2009 27.3196 35.9243 24.7206 33.6512 22.1198C31.9711 20.1979 30.2911 18.276 28.6145 16.3541C28.5032 16.2255 28.4075 16.0815 28.1606 16.0147C28.1501 16.241 28.131 16.4673 28.131 16.6936C28.1293 19.0321 28.1397 21.3706 28.1154 23.7091C28.1119 24.0039 28.0023 24.3622 27.811 24.5817C26.2857 26.3458 24.7205 28.0757 23.19 29.8347C22.8804 30.1982 22.5604 30.3542 22.0282 30.1742Z" fill="#003594"/>*/}
            {/*    <path d="M5.99986 19.2211C5.99986 20.7916 6.01377 22.2746 5.98595 23.7558C5.98073 24.0319 5.87464 24.3627 5.6955 24.5685C4.15285 26.3463 2.59106 28.1088 1.01189 29.8558C0.808402 30.0804 0.474479 30.1918 0.04838 30.4473C0.0240314 29.9929 0.00142133 29.7632 0.00142133 29.5335C-0.000317849 21.8185 -0.00379589 14.1036 0.0153351 6.38689C0.0153351 6.05257 0.15447 5.64797 0.371868 5.39766C1.83278 3.72266 3.31804 2.06994 4.83287 0.442945C5.05026 0.209782 5.46245 0.0400532 5.78942 0.0331955C7.56512 -0.0062365 9.3443 0.0143366 11.1217 0.0143366C15.3184 0.0143366 19.0907 2.95974 19.9881 6.98866C20.5012 9.28943 20.4838 11.5919 19.649 13.8293C18.442 17.0627 15.2106 19.1851 11.4905 19.2177C9.69388 19.2331 7.89557 19.2211 5.99986 19.2211ZM5.95812 13.2309C7.85904 13.2309 9.67649 13.2378 11.4939 13.2292C12.6314 13.2241 13.4975 12.6463 13.9027 11.5971C14.374 10.3729 14.3845 9.1197 13.9688 7.88016C13.5653 6.67834 12.7114 6.08686 11.4209 6.07829C9.80171 6.06801 8.18079 6.09886 6.56161 6.06286C6.00334 6.05086 5.89899 6.26516 5.9042 6.74864C5.92507 8.69109 5.91116 10.6335 5.91464 12.576C5.9129 12.768 5.93899 12.96 5.95812 13.2309Z" fill="#003594"/>*/}
            {/*    <path d="M53.1698 10.3244C55.8151 10.3244 58.3769 10.3244 61.104 10.3244C60.6657 10.8182 60.337 11.1902 60.0083 11.5623C58.7543 12.9801 57.516 14.4099 56.2325 15.8021C56.0151 16.0369 55.6169 16.1947 55.2847 16.2272C54.6081 16.2924 53.9212 16.2461 53.1698 16.2461C53.1698 16.5787 53.1698 16.8153 53.1698 17.0502C53.1698 19.2584 53.1837 21.4648 53.1542 23.673C53.1507 23.9885 53.022 24.3674 52.815 24.6057C51.3072 26.3544 49.7541 28.0671 48.2392 29.8107C47.9105 30.1896 47.5731 30.3525 47.0044 30.1707C47.0044 29.965 47.0044 29.7318 47.0044 29.497C47.0044 22.0666 46.9992 14.6345 47.0218 7.20416C47.0236 6.85442 47.1888 6.43438 47.4201 6.16693C49.0914 4.23819 50.7924 2.33345 52.5072 0.44243C52.7002 0.22984 53.0481 0.0292518 53.3264 0.0292518C58.7474 0.00524975 64.1684 0.0121064 69.5894 0.0121064C69.6973 0.0121064 69.8034 0.0292516 69.9999 0.0463959C69.8868 0.212696 69.819 0.334421 69.7303 0.437287C68.212 2.17915 66.7041 3.92788 65.1545 5.64231C64.958 5.86004 64.551 5.99548 64.2415 5.9972C60.7979 6.0212 57.3543 6.02463 53.9107 6.00063C53.3385 5.9972 53.1316 6.13264 53.1559 6.72755C53.2029 7.90365 53.1698 9.07804 53.1698 10.3244Z" fill="#003594"/>*/}
            {/*</svg>*/}
            <ReactSVG src={'/PKF_logo.svg'} />
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
