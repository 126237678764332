import React, {FunctionComponent, ReactNode, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Box,
    Typography
} from '@mui/material';
import {useTranslation} from "react-i18next";
import {ButtonPrimary} from "../index";
import FormBuilder, {FormInput} from "../FormBuilder";
import * as Yup from "yup";
import {logout} from "../../store/actions/AccountActions";

interface DialogConfirmInterface {
    open: boolean;
    onConfirmDialogClose?: Function;
    text?: ReactNode | string;
    loading?: boolean;
    title?: string;
    handleChange?:Function
    passwordAsk?: boolean,
    onYesClick: Function;
}

const ConfirmationDialog: FunctionComponent<DialogConfirmInterface> = ({
                                                                           open,
                                                                           onConfirmDialogClose = () => {
                                                                           },
                                                                           handleChange = (e:any,value:any) =>{},
                                                                           text = '',
                                                                           loading = false,
                                                                           title = 'Confirmation',
                                                                           passwordAsk = false,
                                                                           onYesClick = () => {
                                                                           }
                                                                       }) => {
    const {t} = useTranslation();
    const [passwordUser,setPasswordUser] = useState('')

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={() => onConfirmDialogClose()}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText color={'text.primary'}>
                    {text}
                </DialogContentText>
              {passwordAsk ?  <Box sx={{mt:'20px'}} >
                <Typography>
                    {t('translate_key_password_confirm') + ' : ' }
                </Typography>
                  <Box sx={{mt:'15px'}}  >
                        <TextField
                            fullWidth
                            type={"password"}
                            onChange={e => {
                                handleChange(e, e.target.value)
                                setPasswordUser(e.target.value)
                            } }
                            label={t("label_password")}
                        />
                  </Box>

              </Box>  : null}
            </DialogContent>
            <DialogActions>
                <ButtonPrimary
                    loading={loading}
                    color={'primary'}
                    disabled={passwordAsk ? passwordUser == '' : false}
                    onClick={() => onYesClick()}
                    variant="contained">
                    <>{t('global_label_confirm')}</>
                </ButtonPrimary>
                <Button
                    onClick={() => onConfirmDialogClose()}
                    variant="outlined"
                    color={'error'}
                    sx={{
                        color: 'text.primary'
                    }}
                    disabled={loading}>
                    <>{t('delete_cancel')}</>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
