import React, { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { MultiSelectOption } from "./FormBuilder";
import { InputSelectIcon } from "../theme/overrides/CustomIcons";


export interface AsyncAutocompleteProps {
    value?: any;
    onChange?: (value: any) => void;
    mapResultRequestToAutocompleteData: (data: any) => MultiSelectOption[];
    httpServiceProvider: (keyword: string) => any;
    noOptionText?: string;
    loadingText?: string;
    label?: string;
    color?: string;
    multiple?: boolean;
    keywordEmptyPlaceholder?: string;
    disabled?:boolean
}

export default function (props: AsyncAutocompleteProps) {

    const [autoCompleteData, setAutoCompleteData] = useState<MultiSelectOption[]>(
        []
    );

    const [loading, setLoading] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [timerId, setTimerId] = useState<any | undefined>();

    const { color } = props;

    useEffect(() => {
        if (keyword.length > 0) {
            if (timerId) clearTimeout(timerId);
            setTimerId(setTimeout(fetchResidences, 500));
        }
    }, [keyword]);

    const fetchResidences = () => {
        setLoading(true);
        props
            .httpServiceProvider(keyword)
            .then(async (response: any) => {
                if (response.status === 200 || response.status === 201) {
                    const result = await response.json();
                    setAutoCompleteData(props.mapResultRequestToAutocompleteData(result));
                    setOpen(true);
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(t('shared.internet_connexion_error'), {
                    variant: 'error'
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Autocomplete
            fullWidth
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            onChange={(event: any, value: any) =>
                props.onChange && props.onChange(value)
            }
            value={props.value || null}
            disabled={props.disabled}
            readOnly={props.disabled}
            multiple={props.multiple}
            loadingText={props.loadingText && t(props.loadingText)}
            noOptionsText={t(
                loading
                    ? props.loadingText || 'auto_complete_loading'
                    : keyword.length === 0
                        ? props.keywordEmptyPlaceholder || 'auto_complete_keyword_empty'
                        : props.noOptionText || 'auto_complete_empty'
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.label}` || ''}
            options={autoCompleteData}
            popupIcon={
                <InputSelectIcon />
            }
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& label': {
                            fontSize: 14,
                            transform: 'translate(14px,12px) scale(1)',
                            color: color !== 'dark' ? '#ffffff' : '#B8B8B8',
                            // opacity: 1
                        },
                        '& input': {
                            padding: '3px 19px!important',
                            color: color !== 'dark' ? '#ffffff' : 'text.primary',
                            fontSize: 14
                        },
                        '& legend span': {
                            fontSize: 11
                        },
                        '& label.Mui-focused, label.MuiFormLabel-filled': {
                            transform: 'translate(16px, -8px) scale(.75)'
                        },
                        '& label.Mui-focused': {
                            color: color !== 'dark' ? '#ffffff' : 'primary.main',
                        },
                        '& label.MuiFormLabel-filled': {
                            // opacity:  color !== 'dark' ? .5 : 1,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: color !== 'dark' ? '#FFFFFF!important' : '#B8B8B8',
                        },
                        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderWidth: '2px!important',
                            borderColor: color !== 'dark' ? '#FFFFFF' : 'primary.main!important',
                        },
                    }}
                    label={props.label && t(`${props.label}`)}
                    onChange={(event) => {
                        event.persist();
                        setKeyword(event.target.value);
                    }}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}
