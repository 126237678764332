import { Box, Icon, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
interface ButtonProps {
    onMobileNavOpen: any;
    className?: string;
    title?:string,
    icon?:string,
    endIcon?:string,
    onPress?:(event: React.MouseEvent<HTMLElement>)=>void
}

export const ButtonToDecorate = (props:ButtonProps) =>{
    const { onMobileNavOpen,onPress, className, title,icon, endIcon, ...rest } = props;

    return(
        <Box onClick={onPress} sx={{display:'flex'}} className={className} {...rest} >
            <img src={icon} alt={title} style={{marginRight:'8px'}} />
            <Typography>
                {title}
            </Typography>
            {endIcon &&  <img src={endIcon} style={{marginLeft: '8px'}}/>}
        </Box>
    )


}