import {SafeZoneUrl} from "./urls";
import BaseService from "./BaseService";

export default class SafeService {
    static sign_in = (data: any) => {
        return BaseService.postRequest(SafeZoneUrl.SIGN_IN, data, true);
    }
    static login = (data: any) => {
        return BaseService.postRequest(SafeZoneUrl.LOGIN, data, true);
    }
    static resetCodeOrQuestion = (data:any,isToReset:boolean) =>{
        // console.log({isToReset})
        return isToReset ? BaseService.putRequest(SafeZoneUrl.UPDATE_CODE_OR_QUESTION_VALIDATE,data,true) : BaseService.putRequest(SafeZoneUrl.UPDATE_CODE_OR_QUESTION,data,true)
    }
    
};